import React from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { thumbnailPlugin } from "@react-pdf-viewer/thumbnail";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "./index.css";

export const pageThumbnailPlugin = (props) => {
  const { PageThumbnail } = props;

  return {
    renderViewer: (renderProps) => {
      let { slot } = renderProps;

      slot.children = PageThumbnail;

      // Reset the sub slot
      slot.subSlot.attrs = {};
      slot.subSlot.children = <></>;

      return slot;
    },
  };
};

function PDFThumbnail({ fileUrl, pageIndex = 0 }) {
  const thumbnailPluginInstance = thumbnailPlugin();

  const { Cover } = thumbnailPluginInstance;
  const pageThumbnailPluginInstance = pageThumbnailPlugin({
    PageThumbnail: <Cover width={140} getPageIndex={() => pageIndex} />,
  });
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
      <Viewer
        fileUrl={fileUrl}
        plugins={[pageThumbnailPluginInstance, thumbnailPluginInstance]}
      />
    </Worker>
  );
}

export default PDFThumbnail;
