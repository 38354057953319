import React from "react";
import { Box } from "@mui/material";
import File from "./File";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  filesContainer: {
    padding: 24,
    paddingTop: 0,
    maxWidth: 900,
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingTop: 0,
    },
  },
  gridViewFilesContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "start",
    flexWrap: "wrap",
    rowGap: 32,
    columnGap: 20,
    // [theme.breakpoints.up(1050)]: {
    //   width: 900,
    //   margin: "0 auto",
    // },
    [theme.breakpoints.down("sm")]: {
      rowGap: 24,
      columnGap: 16,
    },
  },
}));

function Results({
  files,
  isGridView,
  handleSelectFile,
  handleDeleteFile,
  handleUpdateFile,
}) {
  const { classes } = useStyles();
  return (
    <Box flex={1} overflow={"auto"}>
      <Box
        className={`${classes.filesContainer} ${
          isGridView ? classes.gridViewFilesContainer : null
        }`}>
        {files.map((file, i) => {
          const { id } = file;
          return (
            <File
              key={id}
              index={i}
              file={file}
              isGridView={isGridView}
              handleSelectFile={handleSelectFile}
              handleDeleteFile={handleDeleteFile}
              handleUpdateFile={handleUpdateFile}
            />
          );
        })}
      </Box>
    </Box>
  );
}

export default Results;
