import React from "react";
import { Box, Typography, Divider } from "@mui/material";
import FileUploadButton from "./FileUploadButton";

function Header() {
  return (
    <>
      <Box
        px={{ xs: 2, sm: 3 }}
        py={2}
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}>
        <Typography variant="h6">Files</Typography>
        <FileUploadButton />
      </Box>
      <Divider />
    </>
  );
}

export default Header;
