import React from "react";
import { Box, Avatar } from "@mui/material";
import AnswerEntity from "./AnswerEntity";
import StreamAnswer from "./StreamAnswer";
import AnswerAvatar from "./AnswerAvatar";

function Answer({ answer, handleEntityChange = () => {} }) {
  const isEntityAnswer = answer["content_object"]["type"] === "entity";
  const isStreamApiFail =
    answer["error"] && answer["error"] === "stream_api_fail";
  const isAnswerFound = answer["content_object"]["answer_found"];

  return (
    <Box
      display={"flex"}
      alignItems={"start"}
      pt={isEntityAnswer || !isAnswerFound || isStreamApiFail ? 3.5 : 0}>
      <AnswerAvatar />
      {isEntityAnswer ? (
        <AnswerEntity answer={answer} handleEntityChange={handleEntityChange} />
      ) : (
        <StreamAnswer answer={answer} />
      )}
    </Box>
  );
}

export default Answer;
