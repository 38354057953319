import moment from "moment";
import { BsDot } from "react-icons/bs";

export function formatDate(date) {
  const momentDate = moment(date);
  return (
    <span style={{ display: "flex", alignItems: "center" }}>
      {isYesterday(momentDate) ? "Yesterday" : momentDate.format("Do MMM YYYY")}{" "}
      <BsDot />
      {momentDate.format("h:mm A")}
    </span>
  );
}

export function groupByDate(data) {
  const items = [...data].sort(
    (item1, item2) =>
      Date.parse(item2["updated_at"]) - Date.parse(item1["updated_at"])
  );
  const groups = {};
  items.forEach((item) => {
    const date = moment(item["updated_at"]);
    const dayFormat = date.format("Do MMM YYYY");

    if (isToday(date)) {
      if (groups["Today"]) {
        groups["Today"].push(item);
      } else {
        groups["Today"] = [item];
      }
    } else if (isYesterday(date)) {
      if (groups["Yesterday"]) {
        groups["Yesterday"].push(item);
      } else {
        groups["Yesterday"] = [item];
      }
    } else {
      if (groups[dayFormat]) {
        groups[dayFormat].push(item);
      } else {
        groups[dayFormat] = [item];
      }
    }
  });

  const groupsArray = Object.keys(groups).map((date) => {
    return {
      date,
      items: groups[date],
    };
  });
  return groupsArray;
}

export function getSessionItemDate(date) {
  const momentDate = moment(date);
  const now = moment();
  const differenceInHours = now.diff(momentDate, "hours");
  const differenceInMinutes = now.diff(momentDate, "minutes");
  const differenceInSeconds = now.diff(momentDate, "seconds");

  // if the day of the date is same as current date's day, then whatsapp like date format
  if (isToday(momentDate)) {
    if (differenceInSeconds < 60) {
      return "just now";
    } else if (differenceInMinutes < 60) {
      const isDifferenceEqualOneMinute = differenceInMinutes === 1;
      return isDifferenceEqualOneMinute
        ? `a minute ago`
        : `${differenceInMinutes} minutes ago`;
    } else if (differenceInHours < 24) {
      return `${differenceInHours} hour${differenceInHours > 1 ? "s" : ""} ago`;
    }
  }
  return formatDate(date);
}

function isYesterday(date) {
  const yesterday = moment().subtract(1, "day");
  return date.isSame(yesterday, "day");
}

function isToday(date) {
  const today = moment();
  return date.isSame(today, "day");
}

export const AI_GENERATED_ILLUSTRATION =
  "https://res.cloudinary.com/ravenapp/image/upload/v1705045125/ai_copilot/AI_Generated_Illustration_pf5ozj.png";

export const defaultLottieOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const toTitleCase = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getGreeting = () => {
  const currentHour = moment().hour();

  if (currentHour >= 5 && currentHour < 12) {
    return "Good Morning";
  } else if (currentHour >= 12 && currentHour < 17) {
    return "Good Afternoon";
  }
  return "Good Evening";
};

export const getUserNameShortform = (name) => {
  if (!name) {
    return "U";
  }
  // const arr = name.split(" ");
  // if (arr.length > 1) {
  //   return `${arr[0][0]}${arr[1][0]}`.toUpperCase();
  // }
  return name[0];
};

export const models = ["gpt-4o", "claude-3.5-sonnet"];

// can be a list of cloudinary image urls
const newSessionBgImages = [
  "https://res.cloudinary.com/ravenapp/image/upload/fl_progressive/v1724153139/ai_copilot/new-session-bg-images/Raven-AI-Cover-Illustration_11_v2lnlf.jpg",
  "https://res.cloudinary.com/ravenapp/image/upload/fl_progressive/v1724153138/ai_copilot/new-session-bg-images/Raven-AI-Cover-Illustration_7_thyi4z.jpg",
  "https://res.cloudinary.com/ravenapp/image/upload/fl_progressive/v1724153138/ai_copilot/new-session-bg-images/Raven-AI-Cover-Illustration_15_sku0m2.jpg",
  "https://res.cloudinary.com/ravenapp/image/upload/fl_progressive/v1724153137/ai_copilot/new-session-bg-images/Raven-AI-Cover-Illustration_14_izfno9.jpg",
  "https://res.cloudinary.com/ravenapp/image/upload/fl_progressive/v1724153137/ai_copilot/new-session-bg-images/Raven-AI-Cover-Illustration_10_o9altx.jpg",
  "https://res.cloudinary.com/ravenapp/image/upload/fl_progressive/v1724153136/ai_copilot/new-session-bg-images/Raven-AI-Cover-Illustration_4_kga2mw.jpg",
  "https://res.cloudinary.com/ravenapp/image/upload/fl_progressive/v1724153137/ai_copilot/new-session-bg-images/Raven-AI-Cover-Illustration_12_rn9gac.jpg",
  "https://res.cloudinary.com/ravenapp/image/upload/fl_progressive/v1724153137/ai_copilot/new-session-bg-images/Raven-AI-Cover-Illustration_13_ctcbjp.jpg",
  "https://res.cloudinary.com/ravenapp/image/upload/fl_progressive/v1724153136/ai_copilot/new-session-bg-images/Raven-AI-Cover-Illustration_5_xnsaft.jpg",
  "https://res.cloudinary.com/ravenapp/image/upload/fl_progressive/v1724153136/ai_copilot/new-session-bg-images/Raven-AI-Cover-Illustration_8_lz082t.jpg",
  "https://res.cloudinary.com/ravenapp/image/upload/fl_progressive/v1724153135/ai_copilot/new-session-bg-images/Raven-AI-Cover-Illustration_6_myzk5a.jpg",
  "https://res.cloudinary.com/ravenapp/image/upload/fl_progressive/v1724153134/ai_copilot/new-session-bg-images/Raven-AI-Cover-Illustration_9_d6mkw7.jpg",
  "https://res.cloudinary.com/ravenapp/image/upload/fl_progressive/v1724153135/ai_copilot/new-session-bg-images/Raven-AI-Cover-Illustration_3_zcmqam.jpg",
  "https://res.cloudinary.com/ravenapp/image/upload/fl_progressive/v1724153133/ai_copilot/new-session-bg-images/Raven-AI-Cover-Illustration_1_fp6azv.jpg",
  "https://res.cloudinary.com/ravenapp/image/upload/fl_progressive/v1724153134/ai_copilot/new-session-bg-images/Raven-AI-Cover-Illustration_2_h6kqrt.jpg",
];

export function getRandomBgImage() {
  if (newSessionBgImages.length === 0) {
    return null;
  }
  const randomIndex = Math.floor(Math.random() * newSessionBgImages.length);

  return newSessionBgImages[randomIndex];
}

String.prototype.format = function () {
  return [...arguments].reduce((p, c) => p.replace(/%s/, c), this);
};
