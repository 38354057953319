import React from "react";
import { v4 as uuidv4 } from "uuid";

export function GradientIconAccent({ sx, Icon, className }) {
  const id = `paint0_linear_${uuidv4()}`;
  return (
    <>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id={id} x1={1} y1={0} x2={1} y2={1}>
            <stop stopColor="#BF40AE" />
            <stop offset="0.5" stopColor="#6B40BF" />
            <stop offset="1" stopColor="#4E40BF" />
          </linearGradient>
        </defs>
      </svg>
      <Icon sx={{ ...sx, fill: `url(#${id})` }} className={className} />
    </>
  );
}

export function GradientIconLight({ sx, Icon }) {
  const id = `paint0_linear_${uuidv4()}`;
  return (
    <>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id={id} x1={1} y1={0} x2={1} y2={1}>
            <stop stopColor="#732668" />
            <stop offset="0.5" stopColor="#402673" />
            <stop offset="1" stopColor="#2F2673" />
          </linearGradient>
        </defs>
      </svg>
      <Icon sx={{ ...sx, fill: `url(#${id})` }} />
    </>
  );
}

// export function GradientIconAccent({ Icon, width = 24, height = 24, sx = {} }) {
//   const id = `paint0_linear_${uuidv4()}`;
//   return (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       width={width}
//       height={height}
//       fill="none">
//       <defs>
//         <linearGradient
//           id={id}
//           x1={1}
//           y1={0}
//           x2={1}
//           y2={1}
//           gradientUnits="userSpaceOnUse">
// <stop stop-color="#BF40AE" />
// <stop offset="0.5" stop-color="#6B40BF" />
// <stop offset="1" stop-color="#4E40BF" />
//         </linearGradient>
//       </defs>
//       <Icon
//         sx={{ transform: "scaleX(-1) rotate(180deg)", fill: `url(#${id})` }}
//       />
//     </svg>
//   );
// }

// export function GradientIconLight({ Icon, width = 24, height = 24, sx = {} }) {
//   const id = `paint0_linear_${uuidv4()}`;
//   return (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       width={width}
//       height={height}
//       fill="none">
//       <Icon sx={{ ...sx, fill: `url(#${id})` }} />
//       <defs>
//         <linearGradient
//           id={id}
//           x1={1}
//           y1={0}
//           x2={1}
//           y2={1}
//           gradientUnits="userSpaceOnUse">
// <stop stop-color="#732668" />
// <stop offset="0.5" stop-color="#402673" />
// <stop offset="1" stop-color="#2F2673" />
//         </linearGradient>
//       </defs>
//     </svg>
//   );
// }
