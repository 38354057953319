import React from "react";
import ReactQuill from "react-quill";
import markdownit from "markdown-it";
import { Box, useTheme } from "@mui/material";

function HighlightText({ editorRef, message, typeOfAnswer }) {
  const theme = useTheme();
  const md = markdownit();
  let html = md.render(message);

  const handleSelectionChange = (range, source) => {
    if (
      source !== "user" ||
      !range ||
      !editorRef.current ||
      typeOfAnswer !== "part_of_answer"
    ) {
      return;
    }
    editorRef.current.editor.format("background", "#FFF59D");
  };

  return (
    <Box
      width={"100%"}
      height={400}
      overflow={"scroll"}
      padding={1.5}
      borderRadius={3}
      sx={{
        background:
          typeOfAnswer === "complete_answer" ? theme.palette.background.light : theme.palette.paper,
      }}
    >
      <ReactQuill
        readOnly
        ref={editorRef}
        theme={null}
        defaultValue={html}
        onChangeSelection={handleSelectionChange}
        style={{ height: "100%", overflowY: "auto" }}
      />
    </Box>
  );
}

export default HighlightText;
