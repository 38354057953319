import React, { useEffect, useState } from "react";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import Header from "./Header";
import PDFView from "../../../components/PDFView";
import axios from "axios";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import "../../../components/PDFView/index.css";

function ViewFile({
  file,
  handleDeleteFile,
  handleUpdateFile,
  handleSelectFile,
}) {
  const theme = useTheme();
  const zoomPluginInstance = zoomPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  const [fileDetails, setFileDetails] = useState({ numPages: 0, size: 0 });

  const fetchFileSize = async () => {
    try {
      const response = await axios.head(file["file_url"]);
      const fileSizeInBytes = response.headers["content-length"];
      const fileSizeInMegaBytes = (fileSizeInBytes / 1024 ** 2).toFixed(2);
      setFileDetails({ ...fileDetails, size: fileSizeInMegaBytes });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchFileSize();
  }, []);

  const handlePagesChange = (numPages) => {
    setFileDetails({ ...fileDetails, numPages: numPages });
  };

  return (
    <Box height={"100%"} display={"flex"} flexDirection={"column"}>
      <Header
        file={{ ...file, ...fileDetails }}
        handleSelectFile={handleSelectFile}
        handleDeleteFile={handleDeleteFile}
        handleUpdateFile={handleUpdateFile}
        zoomPluginInstance={zoomPluginInstance}
        pageNavigationPluginInstance={pageNavigationPluginInstance}
      />
      <Box flex={1} overflow={"auto"} sx={{ background: "#525659" }} pt={3}>
        <PDFView
          file={file}
          scale={isXS ? 0.5 : 1}
          handlePagesChange={handlePagesChange}
          zoomPluginInstance={zoomPluginInstance}
          pageNavigationPluginInstance={pageNavigationPluginInstance}
        />
      </Box>
    </Box>
  );
}

export default ViewFile;
