import React from "react";
import { Avatar } from "@mui/material";

function AnswerAvatar() {
  return (
    <Avatar
      src={"/images/raven_logo_outlined.png"}
      sx={{
        height: 32,
        width: 32,
        mr: 2,
      }}
    />
  );
}

export default AnswerAvatar;
