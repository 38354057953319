import { Box, IconButton, Typography, useTheme } from "@mui/material";
import React from "react";
import FileUploadButton from "./FileUploadButton";
import Lottie from "react-lottie";
import animationData from "../../../lotties/no_file_uploaded_yet.json";
import { defaultLottieOptions } from "../../../utils/utils";
import { makeStyles } from "tss-react/mui";
import { PDFIcon } from "../../../components/CustomIcons";
import { ArrowForward } from "@mui/icons-material";
import { GradientIconLight } from "../../../components/GradientIcon";

const useStyles = makeStyles()((theme) => ({
  gradientText: {
    background: theme.palette.accentGradient,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
}));

function EmptyFilesScreen() {
  const { classes } = useStyles();
  const theme = useTheme();
  return (
    <Box
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}>
      <Lottie
        options={{ ...defaultLottieOptions, animationData: animationData }}
        height={192}
        width={192}
      />
      <Typography variant="h6">No files uploaded yet!</Typography>
      <Box mb={1} />
      <Typography>Upload a file to get started.</Typography>
      <Box mb={3} />
      <FileUploadButton />
    </Box>
    // <Box
    //   p={2}
    //   height={"100%"}
    //   display={"flex"}
    //   flexDirection={"column"}
    //   alignItems={"center"}
    //   justifyContent={"center"}>
    //   <Box width={444} maxWidth={"100%"}>
    //     <Typography variant="h3">
    //       <span className={classes.gradientText}>Knowledge Training</span>
    //     </Typography>
    //     <Box mb={1} />
    //     <Typography variant="h5">Begin by uploading your documents.</Typography>
    //     <Box mb={5} />
    //     <Typography color={"textSecondary"}>
    //       Upload your manuals to start getting answers immediately.
    //     </Typography>
    //     <Box mb={5} />
    //     <FileUploadButton fullWidth={true} />
    //     <Box mb={5} />
    //     <Typography color={"textSecondary"}>
    //       Or, try our demo with a pre-processed file:
    //     </Typography>
    //     <Box mb={1} />
    //     <Box
    //       maxWidth={444}
    //       width={"100%"}
    //       sx={{ background: theme.palette.paper, padding: "4px 8px 4px 4px" }}
    //       borderRadius={25}
    //       display={"flex"}
    //       alignItems={"center"}
    //       justifyContent={"space-between"}>
    //       <Box display={"flex"} alignItems={"center"} mr={1}>
    //         <PDFIcon
    //           wrapperStyle={{
    //             padding: "10px 7px",
    //             borderRadius: "50%",
    //           }}
    //           textStyle={{ fontSize: "9.917px" }}
    //         />
    //         <Box mr={1} />
    //         <Typography variant="label">document_file_name.pdf</Typography>
    //       </Box>
    //       <IconButton>
    //         <GradientIconLight Icon={ArrowForward} />
    //       </IconButton>
    //     </Box>
    //     {/* <Lottie
    //     options={{ ...defaultLottieOptions, animationData: animationData }}
    //     height={192}
    //     width={192}
    //   />
    //   <Typography variant="h4">No files uploaded yet!</Typography>
    //   <Box mb={1} />
    //   <Typography>All your files will appear here.</Typography>
    //   <Box mb={3} />
    //   <FileUploadButton /> */}
    //   </Box>
    // </Box>
  );
}

export default EmptyFilesScreen;
