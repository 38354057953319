import React, { useRef, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Tooltip,
  Chip,
  useTheme,
  Skeleton,
} from "@mui/material";
import PDFThumbnail from "../../../../../components/PDFThumbnail";
import { makeStyles } from "tss-react/mui";
import {
  EditRounded,
  UpdateRounded,
  DeleteRounded,
  TextSnippetRounded,
} from "@mui/icons-material";
import ConfirmationDialog from "../../../../../components/ConfirmationDialog";
import EditFileDialog from "./EditFileDialog";
import useIsOverflow from "../../../../../components/useIsOverflow";
import { formatDate, getSessionItemDate } from "../../../../../utils/utils";
import { PDFIcon } from "../../../../../components/CustomIcons";

const useStyles = makeStyles()((theme, _params, classes) => ({
  pdfItem: {
    cursor: "pointer",
    position: "relative",
    height: 268,
    padding: 8,
    paddingTop: 20,
    backgroundColor: theme.palette.background.light,
    borderRadius: 8,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 12,
    },
    "&:hover": {
      [`& .${classes.actions}`]: {
        display: "flex",
      },
    },
  },
  actions: {
    position: "absolute",
    top: 4,
    right: 4,
    display: "none",
    alignItems: "center",
    padding: 8,
    borderRadius: 4,
    background: "#424242",
  },
  actionIcon: {
    color: "white",
    cursor: "pointer",
  },
  processingIcon: {
    color: `${theme.palette.text.primary} !important`,
  },
  unprocessedFileThumbnail: {
    background:
      "linear-gradient(147deg, #E0E0E0 -0.14%, rgba(224, 224, 224, 0.00) 99.86%)",
    borderRadius: 4,
    padding: "24px 16px",
    height: 180,
  },
  skeleton: {
    height: 8,
    borderRadius: 50,
    background:
      "linear-gradient(90deg, #BDBDBD -0.47%, rgba(189, 189, 189, 0.00) 99.53%)",
  },
  listViewContainer: {
    position: "relative",
    width: "100%",
    padding: "6px 16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 8,
    cursor: "pointer",
    "&:hover": {
      background: "#E0E0E0",
      [`& .${classes.actionsListView}`]: {
        display: "flex",
      },
      [`& .${classes.listViewDate}`]: {
        visibility: "hidden",
      },
    },
  },
  actionsListView: {
    position: "absolute",
    top: 6,
    right: 16,
    zIndex: 1,
    display: "none",
    alignItems: "center",
    padding: 8,
    borderRadius: 4,
    background: "#424242",
  },
  listViewDate: {
    visibility: "visible",
  },
}));

function File({
  index,
  file,
  isGridView,
  handleSelectFile,
  handleDeleteFile,
  handleUpdateFile,
}) {
  const { classes } = useStyles();
  const theme = useTheme();
  const ref = useRef();
  const isOverflow = useIsOverflow(ref);
  const { name, id, file_url, created_at, status } = file;
  const isProcessed = status === "completed";
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  return (
    <>
      {isGridView ? (
        <Box
          width={{ xs: 156, sm: 198 }}
          className={classes.pdfItem}
          onClick={(e) => {
            handleSelectFile(file);
          }}>
          <Box
            width={{ xs: 140, sm: 156 }}
            px={{ xs: 0, sm: 2 }}
            margin={"0 auto"}>
            {isProcessed ? (
              <PDFThumbnail fileUrl={file_url} />
            ) : (
              <UnprocessedFileThumbnail />
            )}
          </Box>
          <Box mb={1} />
          <Tooltip title={name} arrow disableHoverListener={!isOverflow}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}>
              <Box
                display={"flex"}
                mr={1}
                borderRadius={0.5}
                bgcolor={isProcessed ? "#EB4435" : "#9E9E9E"}
                px={0.25}
                py={isProcessed ? 0.5 : 0.25}>
                {isProcessed ? (
                  <Typography
                    fontSize={7}
                    fontWeight={700}
                    fontFamily={"Roboto Condensed"}
                    sx={{ color: "#ffffff", lineHeight: "normal" }}>
                    PDF
                  </Typography>
                ) : (
                  <TextSnippetRounded sx={{ fontSize: 12, color: "#ffffff" }} />
                )}
              </Box>
              <Typography
                ref={ref}
                variant="body2"
                textAlign={"center"}
                sx={{
                  color: isProcessed
                    ? theme.palette.text.secondary
                    : "rgba(0, 0, 0, 0.42)",
                }}
                noWrap>
                {name}
              </Typography>
            </Box>
          </Tooltip>
          <Box
            mt={1}
            height={24}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}>
            {isProcessed ? (
              <Typography
                variant="caption2"
                textAlign={"center"}
                color={"textSecondary"}>
                {getSessionItemDate(created_at)}
              </Typography>
            ) : (
              <Chip
                classes={{ icon: classes.processingIcon }}
                label="Processing"
                icon={<UpdateRounded />}
                size="small"
                sx={{ background: "#FFB74D" }}
              />
            )}
          </Box>
          <Box className={classes.actions}>
            <EditRounded
              className={classes.actionIcon}
              onClick={(e) => {
                e.stopPropagation();
                setIsEditDialogOpen(true);
              }}
            />
            <Divider
              orientation="vertical"
              flexItem
              sx={{ background: "white", margin: "0 8px" }}
            />
            <DeleteRounded
              className={classes.actionIcon}
              onClick={(e) => {
                e.stopPropagation();
                setIsDeleteDialogOpen(true);
              }}
            />
          </Box>
        </Box>
      ) : (
        <Box
          onClick={(e) => {
            handleSelectFile(file);
          }}
          className={classes.listViewContainer}
          sx={{
            background:
              index % 2 === 0 ? theme.palette.background.light : "transparent",
          }}>
          <Box
            width={`calc(100% - ${isProcessed ? 150 : 250}px)`}
            display={"flex"}
            alignItems={"center"}
            py={1.25}>
            <PDFIcon />
            <Box mr={1.5} />
            <Tooltip title={name} arrow disableHoverListener={!isOverflow}>
              <Typography
                ref={ref}
                variant="body2"
                noWrap
                width={"calc(100% - 40px)"}>
                {name}
              </Typography>
            </Tooltip>
          </Box>
          <Box display={"flex"} alignItems={"center"}>
            {!isProcessed && (
              <Chip
                classes={{ icon: classes.processingIcon }}
                label="Processing"
                icon={<UpdateRounded />}
                size="small"
                sx={{ background: "#FFB74D", marginRight: 2 }}
              />
            )}
            <Typography
              className={classes.listViewDate}
              variant="caption2"
              color={"textSecondary"}>
              {formatDate(created_at)}
            </Typography>
            <Box className={classes.actionsListView}>
              <EditRounded
                className={classes.actionIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEditDialogOpen(true);
                }}
              />
              <Divider
                orientation="vertical"
                flexItem
                sx={{ background: "white", margin: "0 8px" }}
              />
              <DeleteRounded
                className={classes.actionIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsDeleteDialogOpen(true);
                }}
              />
            </Box>
          </Box>
        </Box>
      )}
      {isDeleteDialogOpen && (
        <ConfirmationDialog
          title={"Delete File"}
          content={`Do you really want to delete this file? This will permanently delete all messages and file.`}
          noLabel={"cancel"}
          yesLabel={"yes, delete"}
          onNo={() => setIsDeleteDialogOpen(false)}
          onYes={() => handleDeleteFile(id)}
          isDelete={true}
        />
      )}
      {isEditDialogOpen && (
        <EditFileDialog
          file={file}
          handleUpdateFile={handleUpdateFile}
          onClose={() => setIsEditDialogOpen(false)}
        />
      )}
    </>
  );
}

function UnprocessedFileThumbnail() {
  const { classes } = useStyles();
  return (
    <Box className={classes.unprocessedFileThumbnail}>
      <Skeleton variant="rounded" animation="wave" width={"100%"} height={8} />
      <Box mb={1.5} />
      <Skeleton variant="rounded" animation="wave" width={"100%"} height={8} />
      <Box mb={1.5} />
      <Skeleton variant="rounded" animation="wave" width={"100%"} height={8} />
      <Box mb={1.5} />
      <Skeleton variant="rounded" animation="wave" width={64} height={8} />
    </Box>
  );
}

export default File;
