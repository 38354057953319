import { Box, Button, Typography } from "@mui/material";
import React from "react";
import Lottie from "react-lottie";
import animationData from "../../../lotties/no_sessions_yet.json";
import { defaultLottieOptions } from "../../../utils/utils";
import { AddRounded } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FILES_URL } from "../../../Routes";

function NoSessions() {
  const { files, completedFiles } = useSelector((state) => state.files);
  const history = useHistory();

  const handleClickGoToFiles = () => {
    history.push({ pathname: FILES_URL });
  };

  let subtitle = "";
  if (files.length === 0) {
    subtitle = "Upload a file to get started";
  } else if (completedFiles.length === 0) {
    subtitle = "Please wait till your files are processed"
  } else {
    subtitle = "Start a session and ask a question";
  }

    return (
      <Box
        height={"100%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Lottie
          options={{ ...defaultLottieOptions, animationData: animationData }}
          height={192}
          width={192}
        />
        <Box mb={2} />
        <Typography variant="h6">No sessions yet!</Typography>
        <Box mb={1} />
        <Typography>
          {subtitle}
        </Typography>
        <Box mb={3} />
        {files.length === 0 && (
          <Button variant="contained" color="secondary" onClick={handleClickGoToFiles}>
            Go to Files
          </Button>
        )}
      </Box>
    );
}

export default NoSessions;
