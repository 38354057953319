import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, Grid, Skeleton, useTheme, Chip } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import {
  getUserDetails,
  getAccountDetails,
  setUser,
} from "../../../core/storage/localStorage";
import Query from "../Messages/Query";
import { getPromptQuestions } from "../../../core/repo/sessionRepo";
import { useDispatch, useSelector } from "react-redux";
import { createSession } from "../../../core/repo/sessionRepo";
import { useHistory } from "react-router-dom";
import { upgradeRequest } from "../../../core/repo/accountRepo";
import { Autorenew } from "@mui/icons-material";
import {
  getGreeting,
  getRandomBgImage,
  toTitleCase,
} from "../../../utils/utils";

const useStyles = makeStyles()((theme, { showPromptQuestions }, classes) => ({
  container: showPromptQuestions
    ? {
        borderRadius: 32,
        border: "1px solid rgba(189, 189, 189, 0.25)",
        background: "rgba(255, 255, 255, 0.54)",
        backdropFilter: "blur(30px)",
        padding: "32px 24px 24px 24px",
        maxWidth: 900,
        width: "100%",
      }
    : {
        borderRadius: 32,
        padding: "32px 24px",
        background: theme.palette.paper,
      },
  gradientText: {
    background: theme.palette.accentGradient,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  chip: {
    padding: "4px 10px",
    border: `1px solid #BDBDBD`,
    borderRadius: 100,
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
}));

function NewSession() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const user = getUserDetails();
  const { upgrade_request } = user;
  const account = getAccountDetails();
  const { plan, is_org } = account;
  const [showUpgradeRequest, setShowUpgradeRequest] = useState(
    plan === "free" && !upgrade_request
  );
  const [isUpgradeRequestLoading, setIsUpgradeRequestLoading] = useState(false);

  const handleUpgradeClick = async () => {
    setIsUpgradeRequestLoading(true);
    try {
      await dispatch(upgradeRequest());
      setUser({ ...user, isBannerOpen: false, upgrade_request: true });
      window.dispatchEvent(new Event("storage"));
      setShowUpgradeRequest(false);
      // setTimeout(() => {}, 5000);
    } catch (err) {
      console.log(err);
    }
    setIsUpgradeRequestLoading(false);
  };

  return (
    <Box
      p={{ xs: 1, md: 3 }}
      borderRadius={6}
      sx={{
        height: "100%",
        width: "100%",
        background: `linear-gradient(0deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.75) 100%), url(${getRandomBgImage()}) lightgray 50% / cover no-repeat`,
        // background: `url(${getRandomBgImage()}) no-repeat`,
        // backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}>
      {!is_org && (
        <Chip
          icon={
            isUpgradeRequestLoading ? (
              <Autorenew fontSize="small" sx={{ color: "white !important" }} />
            ) : null
          }
          onClick={handleUpgradeClick}
          variant="filled"
          label={
            isUpgradeRequestLoading
              ? "Please wait..."
              : showUpgradeRequest
              ? "🚀 Upgrade"
              : "Upgrade requested"
          }
          sx={{
            background: showUpgradeRequest ? theme.palette.light : "#757575",
            color: "white",
            marginBottom: 2,
          }}
        />
      )}
      <StartSessionContainer showPromptQuestions={true} />
    </Box>
  );
}

export function StartSessionContainer({ showPromptQuestions = false }) {
  const { completedFiles } = useSelector((state) => state.files);
  const { classes } = useStyles({ showPromptQuestions: showPromptQuestions });
  const history = useHistory();
  const dispatch = useDispatch();
  const queryRef = useRef();
  const queryInputRef = useRef();
  const [isMounted, setIsMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { sessions, isSessionsLoading } = useSelector((state) => state.session);
  const [user, setUser] = useState(() => getUserDetails());
  const [promptQuestions, setPromptQuestions] = useState([]);

  async function fetchPromptQuestions() {
    setIsLoading(true);
    try {
      const response = await getPromptQuestions();
      setPromptQuestions(response["prompt_questions"] || []);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    sessions.length === 0 && showPromptQuestions && fetchPromptQuestions();
  }, []);

  useEffect(() => {
    if (queryRef.current) {
      setIsMounted(true);
    }
  }, []);

  useEffect(() => {
    const handleStorage = () => {
      const user = getUserDetails();
      setUser(user);
    };

    window.addEventListener("storage", handleStorage);
    return () => window.removeEventListener("storage", handleStorage);
  }, []);

  //TODO: add files to params.
  async function submitQuery(query, selectedFileIds = []) {
    try {
      const response = await dispatch(createSession(user["id"]));
      const fileFilter = encodeURIComponent(JSON.stringify(selectedFileIds));
      //apply filter only when some filter is present. default is all fiel selected
      history.push({
        pathname: `/sessions/${response["id"]}`,
        search:
          "?" +
          new URLSearchParams({
            query,
            ...(selectedFileIds.length !== completedFiles.length
              ? { fileFilter: fileFilter }
              : {}),
          }).toString(),
      });
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Box className={classes.container}>
      <Box display="flex" justifyContent="center" alignItems="center" my={1}>
        <img
          src="/images/RAVEN_LOGO.png"
          alt="Raven Icon"
          style={{
            width: "40px",
            height: "40px",
            animation: "spin 1.5s linear 1",
          }}
        />
      </Box>
      <Typography variant="h4" textAlign={"center"}>
        {getGreeting() + "" + (user ? ", " : "")}
        <span className={classes.gradientText}>
          {user ? toTitleCase(user["name"]) : ""}
        </span>
      </Typography>
      <Box mb={5} />
      <Query
        ref={queryRef}
        queryInputRef={queryInputRef}
        size="small"
        isNewSession={true}
        handleStreamMessages={submitQuery}
      />
      {sessions.length === 0 &&
        !isSessionsLoading &&
        showPromptQuestions &&
        promptQuestions.length > 0 && (
          <Box mt={3} pl={6.5}>
            <Typography variant="caption">
              Get started with an example below
            </Typography>
            <Box mb={1.5} />
            <Grid container columnSpacing={1.5} sx={{ minHeight: 100 }}>
              {promptQuestions.map((promptQuestion, i) => {
                return (
                  <Grid key={i} item md={6}>
                    {isLoading ? (
                      <Skeleton height={40} sx={{ borderRadius: 4 }} />
                    ) : (
                      <Box
                        onClick={() =>
                          queryRef.current?.setQuery(promptQuestion)
                        }
                        className={classes.chip}
                        mb={i !== promptQuestions.length - 1 ? 2 : 0}>
                        <Typography
                          fontSize={13}
                          sx={{ whiteSpace: "pre-wrap" }}>
                          {promptQuestion}
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        )}
    </Box>
  );
}

export default NewSession;
