import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "../core/reducers";

export function createStore(preloadedState = {}) {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState
  });
  return store;
}
