import React, { useState } from "react";
import {
  useTheme,
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
} from "@mui/material";
import RenderMarkdown from "./RenderMarkdown";

function StreamAnswer({ answer }) {
  const { content_object } = answer;
  // const [openDialog, setOpenDialog] = useState(false);

  // const handleOpenDialog = () => {
  //   setOpenDialog(true);
  // };

  // const handleCloseDialog = () => {
  //   setOpenDialog(false);
  // };

  return (
    <Box>
      {answer["error"] && answer["error"] === "stream_api_fail" ? (
        <Typography>
          There was a problem generating this answer. Please try again.
        </Typography>
      ) : (
        <>
          <RenderMarkdown message={content_object["text"]} />
        </>
      )}
    </Box>
  );
}

export default StreamAnswer;
