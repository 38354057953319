import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Dialog,
  MenuItem,
  Typography,
  Tooltip,
  useTheme,
  IconButton,
  TextField,
  Button,
  Divider,
  Badge,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
} from "@mui/material";
import { useSelector } from "react-redux";
import useIsOverflow from "../../../components/useIsOverflow";
import {
  CheckBox,
  SnippetFolder,
  SearchRounded,
  CheckBoxOutlineBlank,
  IndeterminateCheckBox,
  CloseRounded,
} from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";
// import GradientIcon from "../../../components/GradientIcon";
import { PDFIcon } from "../../../components/CustomIcons";
import { useSnackbar } from "notistack";
import { useLocation, useHistory } from "react-router-dom";

const useStyles = makeStyles()((theme, _params, classes) => ({
  container: {
    display: "flex",
    border: `1px solid #c4c4c4`,
    overflow: "hidden",
    borderRadius: 50,
    padding: "6px 12px",
    cursor: "pointer",
    "&:hover": {
      [`& .${classes.text}`]: {
        width: 30,
        marginLeft: 8,
        opacity: 1,
      },
    },
  },
  active: {
    background: theme.palette.light,
    borderWidth: 0,
  },
  text: {
    width: 0,
    opacity: 0,
    marginLeft: 0,
    transition: "all 0.2s linear",
  },
  activeText: {
    marginLeft: 8,
  },
  paper: {
    width: 800,
  },
  list: {
    padding: 0,
  },
  resetBtn: {
    "&.Mui-disabled": {
      background: "none",
    },
  },
  gradientText: {
    background: theme.palette.accentGradient,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
}));

function FilesListDialog({ selectedFiles, setSelectedFiles }) {
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { classes } = useStyles();
  const theme = useTheme();
  const { files } = useSelector((state) => state.files);
  const [completedFiles, setCompletedFiles] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [query, setQuery] = useState("");
  const [localSelectedFiles, setLocalSelectedFiles] = useState(selectedFiles);

  let isFilterApplied =
    localSelectedFiles.length > 0 &&
    localSelectedFiles.length != completedFiles.length;
  let isAllFilesSelected = localSelectedFiles.length === completedFiles.length;

  const isFilterChanged =
    JSON.stringify(localSelectedFiles.slice().sort()) !==
    JSON.stringify(selectedFiles.slice().sort());

  const isFilterRemoved = localSelectedFiles.length === 0;

  useEffect(() => {
    let arr = files.filter(
      (file) => file["status"].toLowerCase() === "completed"
    );
    setCompletedFiles(arr);
    setFilteredFiles(arr);
  }, [files]);

  useEffect(() => {
    setLocalSelectedFiles(selectedFiles);
  }, [selectedFiles]);

  const handleQueryChange = (query) => {
    setQuery(query);
    let filteredFiles = [];
    if (!query) {
      filteredFiles = completedFiles;
    } else {
      query = query.toLowerCase();
      filteredFiles = files.filter(
        (file) =>
          file["status"] === "completed" &&
          file["name"].toLowerCase().includes(query)
      );
    }
    setFilteredFiles(filteredFiles);
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    handleResetFilters();
  };

  const handleResetFilters = () => {
    handleQueryChange("");
    setLocalSelectedFiles(selectedFiles);
  };

  const handleApplyFilters = () => {
    if (localSelectedFiles.length === 0) {
      enqueueSnackbar("You must select at-least 1 file to proceed.", {
        variant: "warning",
      });
      return;
    }
    if (localSelectedFiles.length === files.length) {
      enqueueSnackbar("Selecting All files is the default setting.", {
        variant: "info",
      });
    }
    setSelectedFiles(localSelectedFiles);
    const pattern = /^\/sessions\/[^\/]+(\/messages\/[^\/]+)?$/;
    if (
      localSelectedFiles.length !== completedFiles.length &&
      pattern.test(location.pathname)
    ) {
      const searchObj = {
        fileFilter: encodeURIComponent(
          JSON.stringify(localSelectedFiles.map((f) => f["id"]))
        ),
      };
      history.push({
        pathname: location.pathname,
        search: "?" + new URLSearchParams(searchObj).toString(),
        state: location.state,
      });
    } else {
      history.push({
        pathname: location.pathname,
        state: location.state,
      });
    }
    handleClose();
  };

  const handleClearFiles = () => {
    setLocalSelectedFiles([]);
  };

  function getFileById(id) {
    const file = files.find((f) => f["id"] === id);
    return file;
  }

  const handleFileClick = (file) => {
    const { id } = file;
    const checked = localSelectedFiles.find((f) => f["id"] === id)
      ? false
      : true;
    if (checked) {
      setLocalSelectedFiles([...localSelectedFiles, file]);
    } else {
      const files = localSelectedFiles.filter((f) => f["id"] !== id);
      setLocalSelectedFiles(files);
    }
  };

  return (
    <>
      <Badge
        color="error"
        variant="dot"
        invisible={!isFilterApplied}
        overlap="circular">
        <Box
          className={`${classes.container} ${
            isFilterApplied ? classes.active : null
          }`}
          onClick={handleOpen}>
          <SnippetFolder
            fontSize="small"
            sx={{
              color: isFilterApplied ? "white" : theme.palette.action.active,
            }}
          />
          {!isFilterApplied && (
            <Typography
              variant="subtitle2"
              className={!open ? classes.text : classes.activeText}>
              Files
            </Typography>
          )}
        </Box>
      </Badge>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle sx={{ padding: 0 }}>
          <Box
            px={3}
            py={2}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}>
            <Typography variant="h6">Ask within specific files</Typography>
            <IconButton edge="end" onClick={handleClose}>
              <CloseRounded />
            </IconButton>
          </Box>
          <Divider />
          <Box display={"flex"} alignItems={"center"} px={2} py={2.5}>
            {localSelectedFiles.length < filteredFiles.length ? (
              <IconButton onClick={() => setLocalSelectedFiles(files)}>
                <CheckBoxOutlineBlank />
              </IconButton>
            ) : (
              <IconButton onClick={handleClearFiles}>
                <IndeterminateCheckBox
                  sx={{ color: theme.palette.text.primary }}
                />
              </IconButton>
            )}
            <Box mr={1} />
            <Box display={"flex"} alignItems={"center"} mr={3}>
              <Typography variant="body2">All</Typography>
              {/* <ArrowDropDown sx={{ color: theme.palette.text.secondary }} /> */}
            </Box>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search files"
              size={"small"}
              value={query}
              onChange={(e) => handleQueryChange(e.target.value)}
              InputProps={{
                startAdornment: (
                  <SearchRounded
                    sx={{ mr: 1, color: theme.palette.action.active }}
                  />
                ),
                style: { borderRadius: 8 },
              }}
            />
          </Box>
          {
            <Box
              px={2}
              display={"flex"}
              alignItems={"center"}
              flexWrap={"wrap"}
              gap={1}
              mb={2}
              maxHeight={116}
              overflow={"scroll"}>
              {!isFilterRemoved && (
                <Typography variant="caption" color={"textSecondary"}>
                  AI will answer within:
                </Typography>
              )}
              {isAllFilesSelected ? (
                <Chip
                  sx={{ fontWeight: 400 }}
                  size="small"
                  key={"All Files"}
                  label={"All Files"}
                  onDelete={handleClearFiles}
                />
              ) : (
                localSelectedFiles.map((file) => (
                  <Chip
                    sx={{ fontWeight: 400 }}
                    size="small"
                    key={file["id"]}
                    label={file["name"]}
                    onDelete={() => handleFileClick(file)}
                  />
                ))
              )}
              {!isFilterRemoved && !isAllFilesSelected && (
                <Button
                  variant="text"
                  sx={{ borderRadius: 16 }}
                  onClick={handleClearFiles}
                  size="small">
                  <span className={classes.gradientText}>Clear all</span>
                </Button>
              )}
            </Box>
          }
        </DialogTitle>
        <DialogContent sx={{ paddingX: 2 }}>
          {filteredFiles.map((file, index) => {
            const { id } = file;
            return (
              <File
                key={id}
                index={index}
                file={file}
                localSelectedFiles={localSelectedFiles}
                handleFileClick={handleFileClick}
              />
            );
          })}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            className={classes.resetBtn}
            variant="text"
            onClick={handleResetFilters}
            disabled={!isFilterChanged}>
            <span className={isFilterChanged ? classes.gradientText : null}>
              Reset
            </span>
          </Button>
          <Button
            variant="contained"
            onClick={handleApplyFilters}
            disabled={!isFilterChanged || isFilterRemoved}>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function File({ index, file, localSelectedFiles, handleFileClick }) {
  const { id, name } = file;
  const theme = useTheme();
  const ref = useRef();
  const isOverflow = useIsOverflow(ref);
  const isFileSelcted = localSelectedFiles.find((f) => f["id"] === id)
    ? true
    : false;

  return (
    <Tooltip title={name} arrow disableHoverListener={!isOverflow}>
      <MenuItem
        onClick={() => handleFileClick(file)}
        sx={{
          maxWidth: "100%",
          background: index % 2 === 0 ? "#F5F5F5" : "white",
          paddingX: 0,
        }}>
        {isFileSelcted ? (
          <IconButton>
            <CheckBox sx={{ color: theme.palette.text.primary }} />
          </IconButton>
        ) : (
          <IconButton>
            <CheckBoxOutlineBlank />
          </IconButton>
        )}
        <Box mr={1.5} />
        <PDFIcon />
        <Box mr={1} />
        <Typography ref={ref} noWrap>
          {name}
        </Typography>
      </MenuItem>
    </Tooltip>
  );
}

export default FilesListDialog;
