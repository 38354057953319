import React, { useState, useEffect } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import AccountDetailsDialog from "./AccountDetailsDialog";
import {
  getAccountDetails,
  getUserDetails,
} from "../../../../core/storage/localStorage";
import { usePostHog } from "posthog-js/react";
import { VIEW_ACCOUNT_DETAILS } from "../../../../utils/posthogEvents";
import { getUserNameShortform } from "../../../../utils/utils";

function Account({ isExpand }) {
  const posthog = usePostHog();
  const [user, setUser] = useState(() => getUserDetails());
  const account = getAccountDetails();
  const name = user ? user["name"] : "";
  const [isAccountDialogOpen, setIsAccountDialogOpen] = useState(false);

  useEffect(() => {
    const handleStorage = () => {
      const user = getUserDetails();
      setUser(user);
    };

    window.addEventListener("storage", handleStorage);
    return () => window.removeEventListener("storage", handleStorage);
  }, []);

  const handleOpen = () => {
    posthog.capture(VIEW_ACCOUNT_DETAILS);
    setIsAccountDialogOpen(true);
  };

  const handleClose = () => {
    setIsAccountDialogOpen(false);
  };

  return (
    <>
      <Box
        p={2}
        display={"flex"}
        alignItems={"center"}
        sx={{ cursor: "pointer" }}
        onClick={handleOpen}>
        <Avatar sx={{ background: "#263238", textTransform: "capitalize" }}>
          {getUserNameShortform(name)}
        </Avatar>
        {isExpand && (
          <Typography variant="subtitle2" noWrap sx={{ ml: 1 }}>
            {name || "-"}
          </Typography>
        )}
      </Box>
      {isAccountDialogOpen && user && account && (
        <AccountDetailsDialog
          user={user}
          account={account}
          onClose={handleClose}
        />
      )}
    </>
  );
}

export default Account;
