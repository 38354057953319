import React from "react";
import { Dialog, DialogContent, DialogActions, Button } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Question from "../components/Message/Question";
import Answer from "../components/Message/Answer";

const useStyles = makeStyles()((theme) => ({
  gradientText: {
    background: theme.palette.accentGradient,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
}));

export default function MessageDialog({ message, onClose }) {
  const { query, answer } = message;
  const { classes } = useStyles();
  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md">
      <DialogContent>
        <Question query={query} />
        <Answer answer={answer} />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          <span className={classes.gradientText}>Close</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
