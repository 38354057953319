import React, { useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { CloseRounded, SegmentRounded } from "@mui/icons-material";
import Menu from "../Sidebar/Menu";
import Account from "../Sidebar/Account";
import { makeStyles } from "tss-react/mui";
import NewChatButton from "../Sidebar/NewChatButton";
import Logo from "../../../components/Logo";

const useStyles = makeStyles()((theme) => ({
  root: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 1600,
    height: "100vh",
    height: "100svh",
    width: "100vw",
    background: "#F4F5FB",
    display: "flex",
    flexDirection: "column",
  },
  gradientText: {
    background: theme.palette.light,
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
}));

function Navbar() {
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box className={open ? classes.root : null}>
      <Box
        px={2}
        py={1}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}>
        <Box display={"flex"} alignItems={"center"}>
          <Logo variant="small" />
          <Box ml={1} />
          <Typography variant="h5" className={classes.gradientText}>
            Raven AI
          </Typography>
        </Box>
        <IconButton onClick={() => setOpen(!open)}>
          {open ? <CloseRounded /> : <SegmentRounded />}
        </IconButton>
      </Box>
      {open && (
        <>
          <NewChatButton isExpand={true} onClose={handleClose} />
          <Menu isExpand={true} onClose={handleClose} />
          <Account isExpand={true} />
        </>
      )}
    </Box>
  );
}

export default Navbar;
