import React from "react";
import { Box, Typography, Avatar, Button } from "@mui/material";
import { getUserDetails } from "../../../../core/storage/localStorage";
import moment from "moment";
import { getUserNameShortform } from "../../../../utils/utils";

function Question({ query }) {
  const { content_object, created_at } = query;
  const user = getUserDetails();
  const { name } = user;

  return (
    <Box mb={1.5} display={"flex"} alignItems={"start"}>
      <Avatar
        sx={{
          height: 32,
          width: 32,
          background: "#263238",
          textTransform: "capitalize",
        }}>
        {getUserNameShortform(name)}
      </Avatar>
      <Box ml={2}>
        <Typography
          variant={"h5"}
          fontWeight={500}
          sx={{ whiteSpace: "pre-wrap" }}>
          {content_object["text"]}
        </Typography>
        <Box mb={1} />
        <Typography variant="caption" color={"textSecondary"}>
          {moment(created_at).format("h:mm A")}
        </Typography>
      </Box>
    </Box>
  );
}

export default Question;
