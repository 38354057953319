import React from "react";
import { Box, Divider, Skeleton } from "@mui/material";
import { AutoModeRounded } from "@mui/icons-material";
import SkeletonLoader from "../../../components/SkeletonLoader";
import CustomTimlineItem from "../../../components/CustomTimlineItem";
import { Timeline } from "@mui/lab";
import { GradientIconAccent } from "../../../components/GradientIcon";
import Question from "./Message/Question";
import RenderMarkdown from "./Message/RenderMarkdown";
import AnswerAvatar from "../components/Message/AnswerAvatar";

function StreamMessage({
  message,
  numOfMessages,
  tempQandA,
  isMetadataLoading,
}) {
  return (
    <>
      {message ? (
        <Timeline
          sx={{
            mt:
              message["query"]["threads"] &&
              message["query"]["threads"].length > 0
                ? -3
                : 2,
          }}>
          <CustomTimlineItem>
            <Content
              tempQandA={tempQandA}
              message={message}
              numOfMessages={numOfMessages}
              isMetadataLoading={isMetadataLoading}
            />
          </CustomTimlineItem>
        </Timeline>
      ) : (
        <Content
          tempQandA={tempQandA}
          message={message}
          numOfMessages={numOfMessages}
          isMetadataLoading={isMetadataLoading}
        />
      )}
    </>
  );
}

function Content({ message, numOfMessages, tempQandA, isMetadataLoading }) {
  return (
    <Box
      sx={{
        minHeight: `calc(100vh - ${
          message || numOfMessages === 0 ? 249 : 219
        }px)`,
      }}>
      {!message && numOfMessages !== 0 && <Divider sx={{ marginY: 6 }} />}
      <Question query={{ content_object: { text: tempQandA["query"] } }} />
      <Box
        visibility={!tempQandA["isEntity"] ? "visible" : "hidden"}
        display={"flex"}
        justifyContent={"flex-end"}
        mb={1.5}>
        <Skeleton variant="rounded" width={180} height={24} />
      </Box>
      <Box display="flex" alignItems="start">
        <GradientIconAccent
          Icon={AutoModeRounded}
          className={"iconLoading"}
          sx={{ fontSize: 32 }}
        />
        <Box mr={2} />
        {tempQandA && tempQandA["response"] ? (
          <RenderMarkdown message={tempQandA["response"]} />
        ) : (
          <SkeletonLoader />
        )}
      </Box>
      {isMetadataLoading && (
        <Box
          sx={{ mb: 5, mt: 5, pl: 6 }}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}>
          <Box display={"flex"} alignItems={"center"}>
            <Skeleton variant="circular" width={30} height={30} />
            <Box mr={1} />
            <Skeleton variant="circular" width={30} height={30} />
            <Box mr={1} />
            <Skeleton variant="circular" width={30} height={30} />
          </Box>
          <Box display={"flex"} alignItems={"center"}>
            <Skeleton variant="rounded" width={180} height={24} />
            <Box mr={3} />
            <Skeleton variant="rounded" width={180} height={24} />
          </Box>
        </Box>
      )}
    </Box>
  );
}
export default StreamMessage;
