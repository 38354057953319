import axios from "./axios";
import {
  AUTHENTICATE_MAGIC_LINK_URL,
  GET_ACCOUNTS_URL,
  REVOKE_SESSION_URL,
  SEND_MAGIC_LINK_URL,
  UPGRADE_REQUEST_URL,
  GET_USER_AND_ACCOUNT_URL,
  UPDATE_USERNAME_URL,
} from "./url";
import { getUserDetails } from "../storage/localStorage";

class AccountService {
  setAxiosInterceptors = ({ onLogout, clearDetails }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        let err = { ...error };
        if (error.response && error.response.status === 401) {
          this.setSession(null);
          const user = getUserDetails();
          if (onLogout && user) {
            onLogout();
          }
        } else if (
          error.response &&
          error.response.status === 404 &&
          error.response.data === "Session could not be found."
        ) {
          this.setSession(null);
          if (clearDetails) {
            clearDetails();
          }
        } else if (error.response && error.response.status === 403) {
          err.message = "Error 403: Permission denied.";
        } else if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status < 500
        ) {
          err.message =
            error.response && error.response.data && error.response.data.error
              ? "Bad request: " + error.response.data.error
              : "";
        } else if (
          error.response &&
          error.response.status >= 500 &&
          error.response.status < 600
        ) {
          console.log(error);
          err.message =
            error.response && error.response.data && error.response.data.error
              ? "Server Error: " + error.response.data.error
              : "";
        }

        return Promise.reject(err);
      }
    );
  };

  getAccount = (key) =>
    new Promise((resolve, reject) => {
      axios
        .get(GET_ACCOUNTS_URL.format(key))
        .then((response) => {
          if (response) {
            resolve(response.data);
            this.setSession({
              userId: response["data"]["user"]["id"],
              accountId: response["data"]["account"]["id"],
            });
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  sendMagicLink = (email) => {
    var redirect_url = `https://${window.location.host}/authenticate`;
    if (window.location.host.includes("localhost")) {
      redirect_url = `http://${window.location.host}/authenticate`;
    }
    return new Promise((resolve, reject) => {
      axios
        .post(SEND_MAGIC_LINK_URL, {
          email,
          redirect_url: redirect_url,
        })
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  authenticateMagicLink = (token) =>
    new Promise((resolve, reject) => {
      axios
        .post(AUTHENTICATE_MAGIC_LINK_URL, { token })
        .then((response) => {
          if (response) {
            resolve(response.data);
            const { id, session_jwt } = response.data["user"];
            const { id: account_id } = response.data["account"];
            this.setSession({
              userId: id,
              accountId: account_id,
              sessionJWT: session_jwt,
            });
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  setSession = (sessionDetails) => {
    if (sessionDetails) {
      const { userId, accountId, sessionJWT } = sessionDetails;
      axios.defaults.headers.common["x-user-id"] = userId;
      axios.defaults.headers.common["x-account-id"] = accountId;
      if (sessionJWT)
        axios.defaults.headers.common["x-session-jwt"] = sessionJWT;
    } else {
      delete axios.defaults.headers.common["x-user-id"];
      delete axios.defaults.headers.common["x-account-id"];
      delete axios.defaults.headers.common["x-session-jwt"];
    }
  };

  upgradeRequest = () =>
    new Promise((resolve, reject) => {
      axios
        .post(UPGRADE_REQUEST_URL)
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  getUserAndAccount = () =>
    new Promise((resolve, reject) => {
      const user = getUserDetails();
      axios
        .get(GET_USER_AND_ACCOUNT_URL, {
          headers: {
            "x-account-id": user["account_id"],
            "x-user-id": user["id"],
            ...(user["session_jwt"] && {
              "x-session-jwt": user["session_jwt"],
            }),
          },
        })
        .then((response) => {
          if (response) {
            resolve(response.data);
            const { id, session_jwt } = response.data["user"];
            const { id: account_id } = response.data["account"];
            this.setSession({
              userId: id,
              accountId: account_id,
              sessionJWT: session_jwt,
            });
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  updateUser = (user) =>
    new Promise((resolve, reject) => {
      axios
        .put(UPDATE_USERNAME_URL, user)
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  logout = () =>
    new Promise((resolve, reject) => {
      axios
        .post(REVOKE_SESSION_URL)
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
}

const accountService = new AccountService();

export default accountService;
