import React, { useState, useRef } from "react";
import {
  Box,
  TextField,
  MenuItem,
  useMediaQuery,
  useTheme,
  IconButton,
  Menu,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import {
  GridView,
  List,
  SearchRounded,
  SortRounded,
} from "@mui/icons-material";
import { usePostHog } from "posthog-js/react";
import { SORT_FILES } from "../../../utils/posthogEvents";

const useStyles = makeStyles()((theme) => ({
  filtersContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: 16,
    [theme.breakpoints.up(1050)]: {
      width: 900,
      margin: "0 auto",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "40px 24px 32px 24px",
    },
  },
  sortIcon: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 8,
  },
}));

function Filters({
  query,
  sortOption,
  sortOptions,
  isGridView,
  handleSearchFiles,
  handleSortFiles,
  handleViewChange,
  numFiles,
}) {
  const noFiles = numFiles === 0;
  const posthog = usePostHog();
  const { classes } = useStyles();
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));

  const handleQueryChange = (e) => {
    const value = e.target.value;
    handleSearchFiles(value);
  };

  const handleSortOptionChange = (value) => {
    handleSortFiles(value);
    posthog.capture(SORT_FILES);
  };

  return (
    <Box className={classes.filtersContainer}>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search files"
        size={isXS ? "small" : "medium"}
        value={query}
        onChange={handleQueryChange}
        InputProps={{
          // disabled: noFiles,
          startAdornment: (
            <SearchRounded sx={{ mr: 1, color: theme.palette.action.active }} />
          ),
          style: { borderRadius: 8 },
        }}
      />
      <Box mr={{ xs: 1, sm: 2 }} />
      {isXS ? (
        <SortFilterMobileView
          sortOptions={sortOptions}
          sortOption={sortOption}
          handleSortOptionChange={handleSortOptionChange}
        />
      ) : (
        <TextField
          select
          InputProps={{
            style: { borderRadius: 8 },
          }}
          variant="outlined"
          label="Sort By"
          value={sortOption}
          onChange={(e) => handleSortOptionChange(e.target.value)}
          sx={{ minWidth: 170 }}>
          {sortOptions.map((option) => {
            const { value, label } = option;
            return (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            );
          })}
        </TextField>
      )}
      <Box ml={1.5} display={"flex"} alignItems={"center"}>
        <Box
          display={"flex"}
          p={1}
          sx={{
            cursor: "pointer",
            borderRadius: 2,
            background: isGridView
              ? theme.palette.action.selected
              : "transparent",
            ":hover": {
              background: theme.palette.action.hover,
            },
          }}
          onClick={() => handleViewChange(true)}>
          <GridView
            sx={{
              color: isGridView
                ? theme.palette.text.primary
                : theme.palette.text.secondary,
            }}
          />
        </Box>
        <Box mr={1.5} />
        <Box
          display={"flex"}
          p={1}
          sx={{
            borderRadius: 2,
            cursor: "pointer",
            background: !isGridView
              ? theme.palette.action.selected
              : "transparent",
          }}
          onClick={() => handleViewChange(false)}>
          <List
            sx={{
              color: !isGridView
                ? theme.palette.text.primary
                : theme.palette.text.secondary,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

function SortFilterMobileView({
  sortOptions,
  sortOption,
  handleSortOptionChange,
}) {
  const { classes } = useStyles();
  const ref = useRef();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectValue = (value) => {
    handleSortOptionChange(value);
    handleClose();
  };
  return (
    <>
      <IconButton
        ref={ref}
        onClick={handleOpen}
        className={classes.sortIcon}>
        <SortRounded />
      </IconButton>
      <Menu open={open} anchorEl={ref.current} onClose={handleClose}>
        {sortOptions.map((option) => {
          const { value, label } = option;
          return (
            <MenuItem
              onClick={() => handleSelectValue(value)}
              selected={sortOption === value}
              key={value}
              value={value}>
              {label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

export default Filters;
