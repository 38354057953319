import React, { useRef, useState } from "react";
import { uploadFile } from "../core/repo/fileRepo";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import { useSnackbar, closeSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import {
  CheckCircleRounded,
  ChevronLeftRounded,
  ChevronRightRounded,
  CloseRounded,
  UpdateRounded,
  UploadFileRounded,
} from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  notificationContainer: {
    background: "#323232",
    zIndex: 1599,
    borderRadius: 12,
    boxShadow:
      "0px 3px 5px -1px rgba(0, 0, 0, 0.20), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)",
  },
}));

function FileUpload({ children, ...rest }) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const hiddenFileInput = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [files, setFiles] = useState();
  const [showProgress, setShowProgress] = useState(true);
  const [progressInfo, setProgressInfo] = useState([]);

  async function handleChange(event) {
    try {
      const files = Array.from(event.target.files);
      setFiles(files);

      let progressInfos = files.map((file) => ({
        progress: 0,
        loaded: 0,
        total: 0,
        fileName: file.name,
      }));

      const uploadPromises = files.map((file, i) =>
        dispatch(
          uploadFile(file, (event) => {
            const { loaded, total, progress } = event;
            let temp = { fileName: file["name"], loaded, total, progress };
            let copy = [...progressInfos];
            copy[i] = temp;
            progressInfos = [...copy];
            setProgressInfo([...copy]);
          })
        )
      );
      await Promise.all(uploadPromises);
      enqueueSnackbar("", {
        content: (key) => (
          <Box
            px={2}
            py={1}
            display={"flex"}
            alignItems={"flex-start"}
            className={classes.notificationContainer}>
            <UpdateRounded sx={{ color: "#ffffff" }} />
            <Box ml={1.5} mr={2}>
              <Typography fontWeight={500} sx={{ color: "#ffffff", mb: 0.5 }}>
                {files.length} Files Processing
              </Typography>
              <Typography variant="body2" sx={{ color: "#ffffff" }}>
                We will notify you when it’s done
              </Typography>
            </Box>
            <Button
              size="small"
              variant="text"
              sx={{ color: "#82B1FF" }}
              onClick={() => closeSnackbar(key)}>
              Dismiss
            </Button>
          </Box>
        ),
        anchorOrigin: {
          horizontal: "right",
          vertical: "bottom",
        },
      });
    } catch (err) {
      if (err["response"]) {
        const { status } = err["response"];
        if (status === 400) {
          enqueueSnackbar("Invalid File", { variant: "error" });
        }
      }
      console.log(err);
    }
    setProgressInfo([]);
  }

  return (
    <>
      <input
        type="file"
        multiple
        onChange={handleChange}
        ref={hiddenFileInput}
        accept=".xls,.xlsx,.csv,.pdf"
        style={{ display: "none" }}
      />
      <div
        {...rest}
        onClick={() => {
          hiddenFileInput.current.click();
        }}>
        {children}
      </div>
      {progressInfo.length > 0 && showProgress && (
        <FileUploadStatus
          progressInfo={progressInfo}
          onClose={() => setShowProgress(false)}
        />
      )}
    </>
  );
}

function FileUploadStatus({ progressInfo, onClose }) {
  const { classes } = useStyles();
  const [isExpand, setIsExpand] = useState(false);
  const total = getSum("total");
  const loaded = getSum("loaded");
  const overallProgress = Math.floor((loaded * 100) / total);
  const numProcessing = progressInfo.filter((p) => p["progress"] !== 1).length;

  function getSum(field) {
    let res = 0;
    progressInfo.forEach((p) => {
      res += p[field];
    });
    return res;
  }

  const handleExpandChange = () => {
    setIsExpand(!isExpand);
  };

  return (
    <Box
      className={classes.notificationContainer}
      position={"fixed"}
      bottom={24}
      right={24}>
      <Box py={1} px={1.5}>
        <Box display={"flex"} alignItems={"center"}>
          <Avatar sx={{ backgroundColor: "#424242" }}>
            <UploadFileRounded />
          </Avatar>
          <Box mr={1} />
          <Typography sx={{ color: "#ffffff" }}>
            {numProcessing === 0
              ? `${progressInfo.length} Files Uploaded`
              : `Uploading ${numProcessing} ${
                  numProcessing > 1 ? "items" : "item"
                }`}
          </Typography>
          <Box mr={1} />
          {isExpand ? (
            <IconButton onClick={handleExpandChange}>
              <ChevronLeftRounded
                sx={{ transform: "rotate(90deg)", color: "#ffffff" }}
              />
            </IconButton>
          ) : (
            <IconButton onClick={handleExpandChange}>
              <ChevronRightRounded
                sx={{ transform: "rotate(90deg)", color: "#ffffff" }}
              />
            </IconButton>
          )}
          <Box mr={1} />
          <IconButton onClick={onClose}>
            <CloseRounded sx={{ color: "#ffffff" }} />
          </IconButton>
        </Box>
        {!isExpand && (
          <LinearProgress
            variant="determinate"
            color="success"
            value={overallProgress}
            sx={{ borderRadius: 2, mt: 1 }}
          />
        )}
      </Box>
      {isExpand && (
        <>
          <Divider sx={{ borderColor: "#424242" }} />
          <Box py={1} px={2} maxHeight={180} overflow={"scroll"}>
            {progressInfo.map((p, i) => {
              const { fileName, progress } = p;
              const isCompleted = progress === 1;
              return (
                <Box
                  key={i}
                  p={1}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}>
                  <Box display={"flex"} alignItems={"center"} mr={1}>
                    <Box
                      display={"flex"}
                      mr={1}
                      borderRadius={0.5}
                      bgcolor={"#EB4435"}
                      px={0.25}
                      py={0.5}>
                      <Typography
                        fontSize={7}
                        fontWeight={700}
                        fontFamily={"Roboto Condensed"}
                        sx={{ color: "#ffffff", lineHeight: "normal" }}>
                        PDF
                      </Typography>
                    </Box>
                    <Typography
                      variant="body2"
                      noWrap
                      sx={{
                        color: isCompleted
                          ? "#ffffff"
                          : "rgba(255, 255, 255, 0.54)",
                      }}>
                      {fileName}
                    </Typography>
                  </Box>
                  {isCompleted ? (
                    <CheckCircleRounded color="success" />
                  ) : (
                    <CircularProgress
                      size={24}
                      variant="determinate"
                      color="info"
                      value={Math.round(progress * 100)}
                    />
                  )}
                </Box>
              );
            })}
          </Box>
        </>
      )}
    </Box>
  );
}

export default FileUpload;
