import React from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  useTheme,
  Typography,
  Tooltip,
  Chip,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { GradientIconAccent } from "../../../../components/GradientIcon";
import {
  ArrowOutwardRounded,
  ErrorOutlineRounded,
  TextSnippet,
  Image,
  ArrowForwardIosRounded,
} from "@mui/icons-material";
import { PDFIcon } from "../../../../components/CustomIcons";
import { useSelector } from "react-redux";

const useStyles = makeStyles()((theme) => ({
  icon: {
    fontSize: "7px !important",
  },
}));

function AnswerEntity({ answer, handleEntityChange }) {
  const theme = useTheme();
  const { classes } = useStyles();
  const { files } = useSelector((state) => state.files);
  const { content_object } = answer;
  const entity = content_object["entity"];

  let entityExists = entity && entity["file_id"] && entity["title"];
  let file = null;
  if (entityExists) {
    file = files.find((f) => f["id"] === entity["file_id"]);
  }

  let entityType = "document";
  if (entityExists && entity["image_url"]) {
    entityType = "image";
  }

  return (
    <Box width={"100%"}>
      <Card
        onClick={() => (entityExists ? handleEntityChange(entity) : null)}
        sx={{
          background: theme.palette.paper,
        }}>
        <CardActionArea sx={{ pointerEvents: entityExists ? "auto" : "none" }}>
          <CardContent sx={{ p: 0 }}>
            <Box
              px={3}
              height={100}
              borderRadius={3}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}>
              <Box
                display={"flex"}
                alignItems={"center"}
                width={"calc(100% - 40px)"}>
                <Box
                  minWidth={60}
                  height={60}
                  display={"flex"}
                  alignItems={"center"}
                  textAlign={"center"}
                  justifyContent={"center"}
                  bgcolor={theme.palette.background.default}
                  borderRadius="50%"
                  overflow="hidden"
                  mr={3}>
                  <GradientIconAccent
                    Icon={
                      !entityExists
                        ? ErrorOutlineRounded
                        : entityType === "document"
                        ? TextSnippet
                        : Image
                    }
                    sx={{ fontSize: 30 }}
                  />
                </Box>
                <Box width="100%">
                  <Typography
                    fontWeight={500}
                    variant="h6"
                    noWrap
                    sx={{
                      width: "calc(100% - 84px)",
                      textOverflow: "ellipsis",
                      fontSize: 18,
                    }}>
                    {entityExists
                      ? entity["title"]
                      : "Sorry, could not find the document"}
                  </Typography>
                  {file && (
                    <Tooltip title={file["name"]}>
                      <Chip
                        sx={{
                          paddingLeft: 0.5,
                          mt: 1,
                          backgroundColor: "white",
                        }}
                        classes={{ icon: classes.icon }}
                        label={file["name"]}
                        size="small"
                        icon={<PDFIcon />}
                        deleteIcon={<ArrowOutwardRounded />}
                        onDelete={() => {}}
                        color="default"
                      />
                    </Tooltip>
                  )}
                </Box>
              </Box>
              {entityExists && (
                <Box ml={2}>
                  <GradientIconAccent
                    Icon={ArrowForwardIosRounded}
                    width={16}
                    height={16}
                  />
                </Box>
              )}
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
}

export default AnswerEntity;
