import React, { useState, useRef } from "react";
import {
  Box,
  IconButton,
  Typography,
  useTheme,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Menu,
  useMediaQuery,
} from "@mui/material";
import {
  ArrowBackIosRounded,
  InfoRounded,
  BorderColorRounded,
  DeleteRounded,
  MoreVertRounded,
} from "@mui/icons-material";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import EditFileDialog from "../FilesList/Results/File/EditFileDialog";
import FileDetailsDialog from "./FileDetailsDialog";
import { usePostHog } from "posthog-js/react";
import { VIEW_FILE_INFO } from "../../../utils/posthogEvents";
import Toolbar from "../../../components/PDFView/Toolbar";

function Header({
  file,
  handleDeleteFile,
  handleUpdateFile,
  handleSelectFile,
  zoomPluginInstance,
  pageNavigationPluginInstance,
}) {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  const { name } = file;

  const handleBack = () => {
    handleSelectFile(null);
  };

  return (
    <Box
      px={{ xs: 2, sm: 3 }}
      py={2}
      display={"flex"}
      alignItems={"center"}
      sx={{
        background: "#323639",
        boxShadow:
          "0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px 0px rgba(0, 0, 0, 0.20)",
      }}
      width={"100%"}>
      <Box
        display={"flex"}
        alignItems={"center"}
        width={"calc(100% - 40px)"}
        mr={1}>
        <IconButton onClick={handleBack} sx={{ mr: 1, color: "white" }}>
          <ArrowBackIosRounded fontSize="small" />
        </IconButton>
        <Typography variant="h6" noWrap sx={{ color: "white" }}>
          {name}
        </Typography>
      </Box>
      {!isXS && (
        <Toolbar
          zoomPluginInstance={zoomPluginInstance}
          pageNavigationPluginInstance={pageNavigationPluginInstance}
        />
      )}
      <Actions
        file={file}
        handleDeleteFile={handleDeleteFile}
        handleUpdateFile={handleUpdateFile}
      />
    </Box>
  );
}

const menu = [
  {
    value: "file_info",
    label: "File Info",
    icon: <InfoRounded />,
  },
  {
    value: "rename_file",
    label: "Rename File",
    icon: <BorderColorRounded />,
  },
  {
    value: "delete_file",
    label: "Delete File",
    icon: <DeleteRounded sx={{ color: "#d32f2f" }} />,
  },
];

function Actions({ file, handleDeleteFile, handleUpdateFile }) {
  const posthog = usePostHog();
  const theme = useTheme();
  const ref = useRef();
  const [open, setOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isFileDetailsDialogOpen, setIsFileDetailsDialogOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMenuItemClick = (action) => {
    switch (action) {
      case "file_info": {
        setIsFileDetailsDialogOpen(true);
        posthog.capture(VIEW_FILE_INFO);
        break;
      }
      case "rename_file": {
        setIsEditDialogOpen(true);
        break;
      }
      case "delete_file": {
        setIsDeleteDialogOpen(true);
        break;
      }
    }
  };

  return (
    <>
      <IconButton ref={ref} onClick={handleOpen}>
        <MoreVertRounded sx={{ color: "white" }} />
      </IconButton>
      <Menu open={open} anchorEl={ref.current} onClose={handleClose}>
        {menu.map((menuItem) => {
          const { value, label, icon } = menuItem;
          const isDeleteFile = value === "delete_file";
          return (
            <MenuItem
              key={value}
              sx={{
                color: isDeleteFile ? theme.palette.error.main : "initial",
              }}
              onClick={() => handleMenuItemClick(value)}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText>{label}</ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
      {isFileDetailsDialogOpen && (
        <FileDetailsDialog
          file={file}
          onClose={() => setIsFileDetailsDialogOpen(false)}
        />
      )}
      {isDeleteDialogOpen && (
        <ConfirmationDialog
          title={"Delete File"}
          content={`Do you really want to delete this file? This will permanently delete all messages and file.`}
          noLabel={"cancel"}
          yesLabel={"yes, delete"}
          onNo={() => setIsDeleteDialogOpen(false)}
          onYes={() => handleDeleteFile(file["id"])}
          isDelete={true}
        />
      )}
      {isEditDialogOpen && (
        <EditFileDialog
          file={file}
          handleUpdateFile={handleUpdateFile}
          onClose={() => setIsEditDialogOpen(false)}
        />
      )}
    </>
  );
}

export default Header;
