import React from "react";
import { Box, Skeleton } from "@mui/material";

function SkeletonLoader() {
  return (
    <Box width={"100%"} mb={5}>
      <Skeleton variant="rounded" height={16} />
      <Box mb={1} />
      <Skeleton variant="rounded" height={16} />
      <Box mb={1} />
      <Skeleton variant="rounded" height={16} />
      <Box mb={1} />
      <Skeleton variant="rounded" height={16} />
      <Box mb={1} />
      <Skeleton variant="rounded" width={"66%"} height={16} />
      <Box mb={1} />
      <Skeleton variant="rounded" width={"33%"} height={16} />
    </Box>
  );
}

export default SkeletonLoader;
