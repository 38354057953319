import React from "react";
import { Box, Typography, Divider } from "@mui/material";

function Header() {
  return (
    <>
      <Box px={{ xs: 2, sm: 3 }} py={2}>
        <Typography variant="h6">Sessions</Typography>
      </Box>
      <Divider />
    </>
  );
}

export default Header;
