import chatService from "../api/chatService";

export async function chatWithAI(
  query,
  conversationId,
  questionId,
  threadId,
  concise,
  fileIds,
  model,
  streamResponse
) {
  try {
    const response = await chatService.chatWithAI(
      query,
      conversationId,
      questionId,
      threadId,
      fileIds,
      concise,
      model
    );
    const reader = response.getReader();
    const decoder = new TextDecoder();

    let steamResp = "";
    while (true) {
      const { value, done } = await reader.read();
      if (done) break;
      const newText = decoder.decode(value, { stream: true });
      steamResp += newText;
      streamResponse(steamResp);
    }
  } catch (error) {
    throw error;
  }
}

export async function getChatMetaData(questionId) {
  try {
    const response = await chatService.getChatMetaData(questionId);
    return response;
  } catch (error) {
    throw error;
  }
}

export function getQueryIntent(
  query,
  conversationId,
  questionId,
  threadId,
  concise,
  fileIds,
  model
) {
  return async (dispatch) => {
    try {
      const response = await chatService.getQueryIntent(
        query,
        conversationId,
        questionId,
        threadId,
        fileIds,
        concise,
        model
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
}

export function getEntity(
  queryIntent,
  query,
  conversationId,
  questionId,
  threadId,
  concise,
  fileIds,
  model
) {
  return async (dispatch) => {
    try {
      const response = await chatService.getEntity(
        queryIntent,
        query,
        conversationId,
        questionId,
        threadId,
        fileIds,
        concise,
        model
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
}
