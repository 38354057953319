import React, { useState } from "react";
import {
  Box,
  IconButton,
  Typography,
  useTheme,
  MenuItem,
  Menu,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  ArrowBackIosRounded,
  MoreVert,
  BorderColor,
  Delete,
} from "@mui/icons-material";
import { useHistory, useLocation } from "react-router-dom";
import { SESSIONS_URL } from "../../../Routes";
import { usePostHog } from "posthog-js/react";
import { GO_BACK_TO_SESSIONS } from "../../../utils/posthogEvents";
import { resetSessionMessagesSuccess } from "../../../core/events/sessionEvents";
import EditSession from "../components/EditSession";
import DeleteSession from "../components/DeleteSession";

function Header({ session }) {
  if (!session) {
    session = { id: "", title: "" };
  }
  const { id } = session;
  const posthog = usePostHog();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();

  const handleBack = () => {
    history.push({ pathname: SESSIONS_URL, state: location.state });
    dispatch(resetSessionMessagesSuccess());
    posthog.capture(GO_BACK_TO_SESSIONS);
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        px={{ xs: 2, sm: 3 }}
        py={2}
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}>
        <Box width={"100%"} display={"flex"} alignItems={"center"}>
          <IconButton edge="start" sx={{ mr: 1 }} onClick={handleBack}>
            <ArrowBackIosRounded fontSize="small" />
          </IconButton>
          <Box
            width={"calc(100% - 36px)"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}>
            <Typography variant="h6" noWrap maxWidth={600}>
              {session["title"] === "~New Conversation"
                ? "New Session"
                : session["title"]}
            </Typography>
            {id !== "new-session" && (
              <IconButton edge="end" onClick={handleOpen}>
                <MoreVert />
              </IconButton>
            )}
            <Menu
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ horizontal: "center" }}>
              <EditSession session={session}>
                <MenuItem>
                  <ListItemIcon>
                    <BorderColor />
                  </ListItemIcon>
                  <ListItemText>Rename Session</ListItemText>
                </MenuItem>
              </EditSession>
              <DeleteSession sessionId={id}>
                <MenuItem>
                  <ListItemIcon>
                    <Delete sx={{ color: theme.palette.error.main }} />
                  </ListItemIcon>
                  <ListItemText sx={{ color: theme.palette.error.main }}>
                    Delete Session
                  </ListItemText>
                </MenuItem>
              </DeleteSession>
            </Menu>
          </Box>
        </Box>
      </Box>
      <Divider />
    </>
  );
}

export default Header;
