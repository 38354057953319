import React from "react";
import { Box, CircularProgress } from "@mui/material";

function Loader() {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        textAlign: "center",
        transform:"translate(-50%,-50%)"
      }}>
      <CircularProgress color="primary" />
    </Box>
  );
}

export default Loader;
