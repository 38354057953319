import React, { useEffect, useRef } from "react";
import Page from "../../../components/Page";
import { Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Results from "./Results";
import NoSessions from "./NoSessions";
import Header from "./Header";
import Loader from "../../../components/Loader";
import { useSelector } from "react-redux";
import { StartSessionContainer } from "../NewSession";
import { useHistory, useLocation } from "react-router-dom";
import { NEW_SESSION_URL } from "../../../Routes";

const useStyles = makeStyles()(() => ({
  root: {
    height: "100%",
  },
  flexBox: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function Sessions() {
  const { classes } = useStyles();
  const sessionsRef = useRef();
  const { sessions, isSessionsLoading, isSessionFetched } = useSelector(
    (state) => state.session
  );
  const { completedFiles } = useSelector((state) => state.files);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (
      !isSessionsLoading &&
      isSessionFetched &&
      completedFiles.length !== 0 &&
      sessions.length === 0
    ) {
      history.push(NEW_SESSION_URL);
    }
  }, [isSessionsLoading, isSessionFetched, sessions.length, history]);

  // preserves scroll position
  useEffect(() => {
    if (isSessionFetched && location.state && sessionsRef.current) {
      sessionsRef.current.scrollTop = location.state.sessionsScrollPosition;
    }
  }, [isSessionFetched]);

  const handleSessionClick = (id) => {
    const scrollPosition = sessionsRef.current
      ? sessionsRef.current.scrollTop
      : 0;

    //pass current scroll position in the state
    history.push({
      pathname: `/sessions/${id}`,
      state: { sessionsScrollPosition: scrollPosition },
    });
  };

  return (
    <Page className={classes.root} title={"Sessions"}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        height={"100%"}
        position={"relative"}>
        <Header />
        <Box ref={sessionsRef} flex={1} overflow={"auto"}>
          <Box
            p={{ xs: 2, md: 3 }}
            maxWidth={900}
            margin={"0 auto"}
            className={completedFiles.length === 0 ? classes.flexBox : null}>
            {completedFiles.length !== 0 && (
              <>
                <StartSessionContainer />
                <Box mb={3} />
              </>
            )}
            {sessions.length === 0 &&
            isSessionFetched &&
            !isSessionsLoading &&
            completedFiles.length === 0 ? (
              <NoSessions />
            ) : (
              <Results
                sessions={sessions}
                handleSessionClick={handleSessionClick}
              />
            )}
          </Box>
        </Box>
        {isSessionsLoading && <Loader />}
      </Box>
    </Page>
  );
}

export default Sessions;
