import React from "react";
import { Box, Typography, Divider, IconButton, Chip } from "@mui/material";
import { ArrowBackIosRounded } from "@mui/icons-material";
import { usePostHog } from "posthog-js/react";
import { GO_BACK_TO_SESSION } from "../../../utils/posthogEvents";
import { useParams, useHistory, useLocation } from "react-router-dom";

function Header({ message }) {
  const { sessionId } = useParams();
  const location = useLocation();
  const history = useHistory();
  const posthog = usePostHog();
  const numFollowUp =
    message && message["query"]["threads"]
      ? message["query"]["threads"].length
      : 0;

  const handleBack = () => {
    history.push({ pathname: `/sessions/${sessionId}`, state: location.state });
    posthog.capture(GO_BACK_TO_SESSION);
  };

  return (
    <>
      <Box
        px={{ xs: 2, sm: 3 }}
        py={2}
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}>
        <Box width={"100%"} display={"flex"} alignItems={"center"}>
          <IconButton edge="start" sx={{ mr: 1 }} onClick={handleBack}>
            <ArrowBackIosRounded fontSize="small" />
          </IconButton>

          <Box
            width={"calc(100% - 36px)"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}>
            <Typography variant="h6" noWrap maxWidth={600}>
              {`Follow-Ups: ${message["query"]["content_object"]["text"]}`}
            </Typography>
            {numFollowUp > 0 && (
              <Chip
                label={`${numFollowUp} Follow-Up${numFollowUp > 1 ? "s" : ""}`}
                sx={{ marginLeft: 1 }}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Divider />
    </>
  );
}

export default Header;
