import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getSessionMessages,
  getAllSessions,
} from "../../../core/repo/sessionRepo";
import { useParams } from "react-router-dom";
import { getSessionMessagesSuccess } from "../../../core/events/sessionEvents";
import Loader from "../../../components/Loader";
import Page from "../../../components/Page";
import { makeStyles } from "tss-react/mui";
import Header from "./Header";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { SESSIONS_URL } from "../../../Routes";
import MessagesComponent from "../components/MessagesComponent";

const useStyles = makeStyles()(() => ({
  root: {
    height: "100%",
  },
}));

function Messages() {
  const history = useHistory();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { sessionId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const { messages, isMessagesLoading, sessions, isSessionsLoading } =
    useSelector((state) => state.session);
  let session = sessions.find((s) => s["id"] === sessionId);
  if (sessionId === "new-session") {
    session = { id: sessionId, title: "New Session" };
  }

  async function fetchMessages() {
    try {
      let tempSessions = sessions;
      if (sessions.length === 0) {
        tempSessions = await dispatch(getAllSessions());
      }
      const isSessionExists = checkIfSessionExists(tempSessions);
      if (!isSessionExists) {
        enqueueSnackbar("You don't have access to this page.", {
          variant: "error",
        });
        history.push({ pathname: SESSIONS_URL });
        return;
      }
      const response = await dispatch(getSessionMessages(sessionId));
      const processedMessages = formQuestionAnswerPairs(
        response["messages"] || []
      );
      dispatch(getSessionMessagesSuccess(processedMessages));
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (sessionId !== "new-session") fetchMessages();
  }, []);

  function checkIfSessionExists(sessions) {
    if (sessions.length === 0) {
      return true;
    }
    const session = sessions.find((s) => s["id"] === sessionId);
    return session ? true : false;
  }

  return (
    <Page className={classes.root} title={"Messages"}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        height={"100%"}
        position={"relative"}>
        {(isSessionsLoading || isMessagesLoading) && <Loader />}
        {!isSessionsLoading && sessions.length > 0 && (
          <Header session={session} />
        )}
        <MessagesComponent messages={messages} session={session} />
      </Box>
    </Page>
  );
}

export function formQuestionAnswerPairs(messages) {
  const questionAnswerPairs = [];
  messages = sortMessages(messages);
  messages.forEach((msg) => {
    if (msg["role"] === "assistant") {
      const query = messages.find(
        (m) => m["message_id"] === msg["question_id"]
      );
      if (query["threads"] && query["threads"].length > 0) {
        let threadQuestionAnswerParis = [];
        const threadMessages = sortMessages(query["threads"]);
        threadMessages.forEach((threadMsg) => {
          if (threadMsg["role"] === "assistant") {
            const q = threadMessages.find(
              (t) => t["message_id"] === threadMsg["question_id"]
            );
            threadQuestionAnswerParis.push({ query: q, answer: threadMsg });
          }
        });
        query["threads"] = threadQuestionAnswerParis;
      }
      questionAnswerPairs.push({ query, answer: msg });
    }
  });
  return questionAnswerPairs;
}

function sortMessages(messages) {
  return messages.sort(
    (msg1, msg2) =>
      Date.parse(msg1["created_at"]) - Date.parse(msg2["created_at"])
  );
}

export default Messages;
