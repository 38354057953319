import React, { useState, useEffect } from "react";
import Header from "./Header";
import Filters from "./Filters";
import Results from "./Results";
import EmptyFilesScreen from "./EmptyFilesScreen";
import {
  getFileGridView,
  getFileGridViewLocalStorage,
  setFileGridView,
  setFileGridViewLocalStorage,
} from "../../../core/storage/localStorage";

const sortOptions = [
  { value: "name", label: "Name" },
  { value: "created_at", label: "Last Uploaded" },
  { value: "updated_at", label: "Last Modified" },
];

function FilesList({ files, loading, handleSelectFile, handleDeleteFile, handleUpdateFile }) {
  const [filteredFiles, setFilteredFiles] = useState(files);
  const [query, setQuery] = useState();
  const [sortOption, setSortOption] = useState(sortOptions[1].value);
  const [isGridView, setIsGridView] = useState(getFileGridViewLocalStorage());

  useEffect(() => {
    const sortedFiles = getSortedFiles(files, sortOption);
    setFilteredFiles(sortedFiles);
  }, [files]);

  function handleSearchFiles(query) {
    setQuery(query);
    let filteredFiles = [];
    if (!query) {
      filteredFiles = files;
    } else {
      query = query.toLowerCase();
      filteredFiles = files.filter((file) => file["name"].toLowerCase().includes(query));
    }
    const sortedFiles = getSortedFiles(filteredFiles, sortOption);
    setFilteredFiles(sortedFiles);
  }

  function handleSortFiles(sortOption) {
    setSortOption(sortOption);
    const sortedFiles = getSortedFiles(filteredFiles, sortOption);
    setFilteredFiles(sortedFiles);
  }

  function getSortedFiles(files, sortOption) {
    const processingFiles = [],
      processedFiles = [];

    files.forEach((file) => {
      if (file["status"] === "completed") {
        processedFiles.push(file);
      } else {
        processingFiles.push(file);
      }
    });
    const sortedFiles = processingFiles.concat(
      [...processedFiles].sort((f1, f2) => {
        switch (sortOption) {
          case "name": {
            return f1["name"].localeCompare(f2["name"]);
          }
          case "created_at": {
            return Date.parse(f2["created_at"]) - Date.parse(f1["created_at"]);
          }
          case "updated_at": {
            return Date.parse(f2["updated_at"]) - Date.parse(f1["updated_at"]);
          }
        }
      })
    );
    return sortedFiles;
  }
  return (
    <>
      <Header />
      {files.length === 0 && !loading ? (
        <EmptyFilesScreen />
      ) : (
        <>
          <Filters
            query={query}
            sortOption={sortOption}
            sortOptions={sortOptions}
            isGridView={isGridView}
            handleSearchFiles={handleSearchFiles}
            handleSortFiles={handleSortFiles}
            handleViewChange={(value) => {
              setIsGridView(value);
              setFileGridViewLocalStorage(value);
            }}
            numFiles={files.length}
          />
          <Results
            files={filteredFiles}
            isGridView={isGridView}
            handleSelectFile={handleSelectFile}
            handleDeleteFile={handleDeleteFile}
            handleUpdateFile={handleUpdateFile}
          />
        </>
      )}
    </>
  );
}

export default FilesList;
