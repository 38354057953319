import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { clearDetails, logout } from "../core/repo/accountRepo";
import { useHistory } from "react-router-dom";
import accountService from "../core/api/accountService";
import SplashScreen from "./SplashScreen";
import { usePostHog } from "posthog-js/react";
import { useSnackbar } from "notistack";
import { LOGOUT_SUCCESS } from "../utils/posthogEvents";

function Auth({ children }) {
  const dispatch = useDispatch();
  const posthog = usePostHog();
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = async () => {
    try {
      dispatch(logout());
      posthog.capture(LOGOUT_SUCCESS);
      history.push({ pathname: "/login" });
    } catch (err) {
      history.push({ pathname: "/login" });
      // enqueueSnackbar("Failed to Logout", {
      //   variant: "error",
      // });
    }
  };

  const handleClearDetails = async () => {
    dispatch(clearDetails());
    history.push({ pathname: "/login" });
  };

  useEffect(() => {
    const initAuth = async () => {
      accountService.setAxiosInterceptors({
        onLogout: handleLogout,
        clearDetails: handleClearDetails,
      });

      setLoading(false);
    };

    initAuth();
  }, [dispatch]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return children;
}

export default Auth;
