import React from "react";
import { Box, CircularProgress } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Logo from "./Logo";

const useStyles = makeStyles()((theme) => ({
  root: {
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    left: 0,
    padding: theme.spacing(3),
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 2000,
  },
}));

function SplashScreen() {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="center" mb={6}>
        <Logo variant="large" isBoxShadow={false} />
      </Box>
      <CircularProgress />
    </div>
  );
}

export default SplashScreen;
