import React from "react";
import {
  TimelineItem,
  TimelineDot,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
} from "@mui/lab";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  missingOppositeContent: {
    "&:before": {
      display: "none",
    },
  },
}));

function CustomTimlineItem({ children }) {
  const { classes } = useStyles();

  return (
    <TimelineItem
      classes={{ missingOppositeContent: classes.missingOppositeContent }}>
      <TimelineSeparator>
        <TimelineDot />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ width: "100%" }}>{children}</TimelineContent>
    </TimelineItem>
  );
}

export default CustomTimlineItem;
