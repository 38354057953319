import React, { useState } from "react";
import {
  ContentCopyOutlined,
  ContentCopyRounded,
  CopyAllOutlined,
  IosShareOutlined,
  LinkRounded,
  ShareOutlined,
  ShareRounded,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  Tooltip,
  useTheme,
  Popover,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useSnackbar } from "notistack";

function ShareMessage({ message }) {
  const { enqueueSnackbar } = useSnackbar();
  const { answer, query } = message;
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopyMessage = () => {
    handleCopy(answer["content_object"]["text"], "Copied message to clipboard");
  };

  const handleCopyMessageLink = () => {
    handleCopy(
      `${window.location.href}?messageId=${query["message_id"]}`,
      "Copied message link to clipboard"
    );
  };

  const handleCopy = (content, toastMessage) => {
    window.navigator.clipboard.writeText(content);
    enqueueSnackbar(toastMessage);
    handleClose();
  };

  return (
    <Box ml={1}>
      <Tooltip title="Share">
        <IconButton
          size="small"
          sx={{ color: theme.palette.action.active }}
          onClick={handleClick}>
          <IosShareOutlined fontSize="small" />
        </IconButton>
      </Tooltip>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        slotProps={{ paper: { style: { borderRadius: 12 } } }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}>
        <MenuList>
          <MenuItem onClick={handleCopyMessageLink}>
            <ListItemIcon>
              <LinkRounded
                sx={{ color: theme.palette.text.disabled, fontSize: 17 }}
              />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                variant: "body2",
                color: "text.secondary",
              }}>
              Copy Message Link
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={handleCopyMessage}>
            <ListItemIcon>
              <ContentCopyRounded
                sx={{ color: theme.palette.text.disabled, fontSize: 17 }}
              />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                variant: "body2",
                color: "text.secondary",
              }}>
              Copy Message Content
            </ListItemText>
          </MenuItem>
        </MenuList>
      </Popover>
    </Box>
  );
}

export default ShareMessage;
