import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Logo from "../../components/Logo";
import Page from "../../components/Page";
import { useDispatch } from "react-redux";
import { sendMagicLink } from "../../core/repo/accountRepo";
import { useSnackbar } from "notistack";
import Loader from "../../components/Loader";
import validator from "validator";

const useStyles = makeStyles()((theme) => ({
  root: {
    background: "#121417",
    minHeight: "100vh",
    minHeight: "100svh",
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  textField: {
    border: "0.763px solid rgba(255, 255, 255, 0.23)",
    padding: 12,
    borderRadius: 8,
    background: "rgba(255, 255, 255, 0.06)",
    "&:hover": {
      borderColor: "whitesmoke",
    },
  },
  container: {
    width: 400,
    padding: 16,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 16,
    background: "rgba(255, 255, 255, 0.02)",
    boxShadow: "0px 8px 30px 0px rgba(0, 0, 0, 0.12)",
    backdropFilter: "blur(12px)",
    [theme.breakpoints.up("sm")]: {
      padding: 24,
      width: 420,
    },
  },
  gradientText: {
    background: theme.palette.dark,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    textDecoration: "underline",
    textDecorationColor: "#EF8FE2",
    textUnderlineOffset: 4,
    cursor: "pointer",
  },
}));

function EmailVerification() {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const email = new URLSearchParams(window.location.search).get("email");
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  async function handleSendEmailMagicLink() {
    setIsLoading(true);
    try {
      if (!validator.isEmail(email)) {
        throw new Error("Enter a valid Email");
      }
      await dispatch(sendMagicLink(email));
      enqueueSnackbar("Verification link has been sent to your email.", {
        variant: "success",
      });
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Failed to send verfication link to your email.", {
        variant: "error",
      });
    }
    setIsLoading(false);
  }

  return (
    <Page className={classes.root} title="Email Verification">
      <Box display={"flex"} alignItems={"center"} mb={5}>
        <Logo isBoxShadow={false} />
        <Box mr={1} />
        <Typography variant="h2" sx={{ color: "white", fontSize: "26.732px" }}>
          Raven AI
        </Typography>
      </Box>
      <Box
        mb={5}
        width={400}
        height={2}
        sx={{
          background:
            "linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #FFF 50%, rgba(255, 255, 255, 0.00) 100%)",
        }}></Box>
      <Box className={classes.container}>
        <Typography variant="h4" sx={{ color: "white" }}>
          An email is on its way!
        </Typography>
        <Box mb={5} />
        <Typography sx={{ color: "white" }}>
          We sent an email to <b>{email}</b>. You'll find a verification link
          that will sign into the Raven AI workspace.
          <br />
          <br /> The link expires in 1 hour, so be sure to use it soon.
        </Typography>
        <Box mb={5} />
        <Typography sx={{ color: "white", width: "100%", textAlign: "left" }}>
          Haven't received the email yet?{" "}
          <span
            className={classes.gradientText}
            onClick={handleSendEmailMagicLink}>
            Resend Email
          </span>
        </Typography>
      </Box>
      {isLoading && <Loader />}
    </Page>
  );
}

export default EmailVerification;
