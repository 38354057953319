import React from "react";
import { Button } from "@mui/material";
import { UploadFile, UploadFileRounded } from "@mui/icons-material";
import FileUpload from "../../../components/FileUpload";

function FileUploadButton({ fullWidth = false }) {
  return (
    <FileUpload>
      <Button
        fullWidth={fullWidth}
        variant="contained"
        color="secondary"
        startIcon={<UploadFile />}
        sx={{ borderRadius: 2 }}>
        Upload File
      </Button>
    </FileUpload>
  );
}

export default FileUploadButton;
