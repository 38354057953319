import React, { Fragment, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Typography,
  Avatar,
  useTheme,
  Chip,
  LinearProgress,
  CircularProgress,
  Switch,
} from "@mui/material";
import {
  Autorenew,
  CheckCircleOutline,
  CheckCircleRounded,
  CloseRounded,
  LogoutRounded,
  RadioButtonCheckedRounded,
  RadioButtonUncheckedRounded,
} from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { logout } from "../../../../core/repo/accountRepo";
import { useDispatch } from "react-redux";
import { usePostHog } from "posthog-js/react";
import { LOGOUT_SUCCESS } from "../../../../utils/posthogEvents";
import { useSnackbar } from "notistack";
import { makeStyles } from "tss-react/mui";
import {
  getAIModel,
  getIsDebugModeOn,
  setAIModel,
  setIsDebugModeOn,
  setUser,
} from "../../../../core/storage/localStorage";
import { upgradeRequest } from "../../../../core/repo/accountRepo";
import {
  getUserNameShortform,
  toTitleCase,
  models,
} from "../../../../utils/utils";
import EditUsername from "./EditUsername";

const useStyles = makeStyles()((theme) => ({
  bar: {
    background: "#D7D7D7",
  },
  bar1Determinate: {
    background: theme.palette.success.main,
    borderRadius: 4,
  },
  gradientText: {
    background: theme.palette.accentGradient,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
}));

function AccountDetailsDialog({ user, account, onClose }) {
  const { classes } = useStyles();
  const posthog = usePostHog();
  const { name, email, num_of_messages, upgrade_request } = user;
  const {
    name: accountName,
    plan,
    cover_image,
    profile_image,
    is_org,
  } = account;
  const isPro = plan !== "free";
  const [isLoading, setIsLoading] = useState(false);
  const [selectedModel, setSelectedModel] = useState(() => getAIModel());
  const [isDebugMode, setIsDebugMode] = useState(() => getIsDebugModeOn());
  const [showUpgradeRequest, setShowUpgradeRequest] = useState(
    !upgrade_request
  );
  const [isUpgradeRequestLoading, setIsUpgradeRequestLoading] = useState(false);
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      await dispatch(logout());
      posthog.capture(LOGOUT_SUCCESS);
      history.push({ pathname: "/login" });
    } catch (err) {
      history.push({ pathname: "/login" });
      // enqueueSnackbar("Failed to Logout", {
      //   variant: "error",
      // });
    }
    setIsLoading(false);
  };

  const handleUpgradeClick = async () => {
    setIsUpgradeRequestLoading(true);
    try {
      await dispatch(upgradeRequest());
      setOpen(true);
      setTimeout(() => {
        setUser({ ...user, isBannerOpen: false, upgrade_request: true });
        window.dispatchEvent(new Event("storage"));
        setOpen(false);
        setShowUpgradeRequest(false);
      }, 5000);
    } catch (err) {
      console.log(err);
    }
    setIsUpgradeRequestLoading(false);
  };

  const handleModelChange = (model) => {
    setAIModel(model);
    setSelectedModel(model);
    window.dispatchEvent(new Event("storage"));
  };

  const handleDebugMode = (e) => {
    const value = e.target.checked;
    setIsDebugModeOn(value);
    setIsDebugMode(value);
    window.dispatchEvent(new Event("storage"));
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth={"xs"}
      sx={{ zIndex: 1700 }}>
      <Box position={"relative"}>
        <Box
          sx={{
            width: "100%",
            height: 77,
            backgroundColor: cover_image ? "transparent" : "grey",
          }}>
          <img
            src={cover_image ? cover_image : "/images/dialog_title.png"}
            width={"100%"}
            height={77}
            style={{ display: "block", objectFit: "cover" }}
          />
        </Box>
        <Avatar
          src={profile_image}
          imgProps={{ style: { objectFit: "contain" } }}
          sx={{
            width: 56,
            height: 56,
            border: "2px solid white",
            position: "absolute",
            left: "50%",
            bottom: 0,
            transform: "translate(-50%,50%)",
            background: isPro
              ? "linear-gradient(139deg, #BCDBD5 14.33%, #867FDF 35.09%, #370084 68.69%)"
              : "#263238",
            textTransform: "capitalize",
          }}>
          {getUserNameShortform(is_org ? accountName : name)}
        </Avatar>
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 16,
            right: 16,
            zIndex: 1,
            bgcolor: "rgba(255, 255, 255, 0.87)",
            ":hover": {
              bgcolor: "rgba(255, 255, 255, 0.87)",
            },
          }}>
          <CloseRounded
            sx={{
              fontSize: 16,
              color: theme.palette.text.primary,
            }}
          />
        </IconButton>
      </Box>
      <Box mt={4}>
        {is_org ? (
          <Typography variant="h5" textAlign={"center"}>
            <span className={isPro ? classes.gradientText : null}>
              {accountName}
            </span>
          </Typography>
        ) : (
          <EditUsername user={user} isCenter={true}>
            <Typography variant="h5" textAlign={"center"} sx={{ ml: 4 }}>
              <span className={isPro ? classes.gradientText : null}>
                {name}
              </span>
            </Typography>
          </EditUsername>
        )}
        {!is_org && (
          <Typography
            variant="body2"
            color={"textSecondary"}
            textAlign={"center"}>
            {email}
          </Typography>
        )}
      </Box>
      <Box px={3} py={2.5}>
        <Box borderRadius={3} bgcolor={theme.palette.background.light} p={1.5}>
          {is_org && (
            <>
              <Box display={"flex"} alignItems={"center"}>
                <Avatar
                  sx={{
                    background: isPro
                      ? "linear-gradient(139deg, #BCDBD5 14.33%, #867FDF 35.09%, #370084 68.69%)"
                      : "#263238",
                    textTransform: "capitalize",
                  }}>
                  {getUserNameShortform(name)}
                </Avatar>
                <Box mr={1} />
                <Box width={"100%"}>
                  <EditUsername user={user}>
                    <Typography fontWeight={500}>{name}</Typography>
                  </EditUsername>
                  <Typography variant="body2" color={"textSecondary"}>
                    {email}
                  </Typography>
                </Box>
              </Box>
              <Divider sx={{ my: 2 }} />
            </>
          )}
          {open ? (
            <Chip
              label="We’ll reach out soon with upgrade steps & payment."
              icon={
                <CheckCircleOutline
                  fontSize="small"
                  sx={{ color: "white !important" }}
                />
              }
              sx={{
                width: "100%",
                background: theme.palette.success.main,
                color: "white",
                justifyContent: "start",
              }}
            />
          ) : (
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}>
              <Typography>
                Plan:
                <Box display={"inline-block"} mr={1.5} />
                <b>
                  <span className={isPro ? classes.gradientText : null}>
                    {toTitleCase(plan)}
                  </span>
                </b>
              </Typography>
              {plan === "free" && !is_org && (
                <Chip
                  icon={
                    isUpgradeRequestLoading ? (
                      <Autorenew
                        fontSize="small"
                        sx={{ color: "white !important" }}
                      />
                    ) : null
                  }
                  onClick={
                    isUpgradeRequestLoading || !showUpgradeRequest
                      ? null
                      : handleUpgradeClick
                  }
                  variant="filled"
                  label={
                    isUpgradeRequestLoading
                      ? "Please wait..."
                      : showUpgradeRequest
                      ? "🚀 Upgrade"
                      : "Upgrade Requested"
                  }
                  sx={{
                    background: showUpgradeRequest
                      ? theme.palette.light
                      : "#757575",
                    color: "white",
                  }}
                />
              )}
            </Box>
          )}
          <Divider sx={{ my: 2 }} />
          <Box>
            <Box
              mb={1}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}>
              <Typography>Query Usage</Typography>
              <Typography variant="body2" color={"textSecondary"}>
                Resets every month
              </Typography>
            </Box>
            <Box width={"100%"} display={"flex"} alignItems={"center"}>
              <LinearProgress
                classes={{
                  root: classes.bar,
                  bar1Determinate: classes.bar1Determinate,
                }}
                variant="determinate"
                value={(num_of_messages || 0) * 2}
                sx={{ width: "100%", borderRadius: 1, height: 8 }}
              />
              <Box mr={3} />
              <Typography variant="body2">{num_of_messages || 0}/50</Typography>
            </Box>
          </Box>
        </Box>
        {process.env.REACT_APP_ENV === "staging" && (
          <Box
            p={1}
            mt={2.5}
            bgcolor={theme.palette.background.light}
            borderRadius={3}>
            <Box display={"flex"} alignItems={"center"}>
              <Typography>Debug Mode</Typography>
              <Box mr={1} />
              <Switch checked={isDebugMode} onChange={handleDebugMode} />
            </Box>
            {isDebugMode && (
              <Box>
                {models.map((model, i) => {
                  const isSelected = selectedModel === model;
                  return (
                    <Fragment key={model}>
                      <Box
                        width={"100%"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleModelChange(model)}>
                        <Typography>{model}</Typography>
                        <IconButton>
                          {isSelected ? (
                            <RadioButtonCheckedRounded />
                          ) : (
                            <RadioButtonUncheckedRounded />
                          )}
                        </IconButton>
                      </Box>
                      {i !== models.length - 1 && (
                        <Divider sx={{ marginY: 0.5 }} />
                      )}
                    </Fragment>
                  );
                })}
              </Box>
            )}
          </Box>
        )}
        {plan === "free" && (
          <Box
            mt={2.5}
            px={1.5}
            py={1}
            borderRadius={2}
            sx={{
              background:
                "linear-gradient(280deg, #E3DFFC 0.82%, #FEFEFF 50.21%, #FCF2FD 99.6%)",
            }}>
            <Typography fontWeight={600}>
              <span className={classes.gradientText}>
                Why you need the PRO plan?
              </span>
            </Typography>
            <Box display={"flex"} alignItems={"center"} py={1}>
              <CheckCircleRounded color="success" />
              <Box mr={1} />
              <Typography variant="body2">No limit on queries</Typography>
            </Box>
            <Box display={"flex"} alignItems={"center"} py={0.5}>
              <CheckCircleRounded color="success" />
              <Box mr={1} />
              <Typography variant="body2">Diagram/Image search</Typography>
            </Box>
            <Box display={"flex"} alignItems={"center"} py={0.5}>
              <CheckCircleRounded color="success" />
              <Box mr={1} />
              <Typography variant="body2">API access</Typography>
            </Box>
            <Box display={"flex"} alignItems={"center"} py={0.5}>
              <CheckCircleRounded color="success" />
              <Box mr={1} />
              <Typography variant="body2">Integrations</Typography>
            </Box>
            <Box display={"flex"} alignItems={"center"} py={0.5}>
              <CheckCircleRounded color="success" />
              <Box mr={1} />
              <Typography variant="body2">
                All capabilities of Free plan
              </Typography>
            </Box>
          </Box>
        )}
        <Button
          variant="outlined"
          color="error"
          sx={{
            width: 122,
            borderRadius: 2,
            marginTop: 2.5,
          }}
          disabled={isLoading}
          endIcon={isLoading ? null : <LogoutRounded />}
          onClick={handleLogout}>
          {isLoading ? (
            <CircularProgress size={24.5} color="error" />
          ) : (
            "Sign Out"
          )}
        </Button>
      </Box>
    </Dialog>
  );
}

export default AccountDetailsDialog;
