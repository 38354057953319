import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  Box,
  InputAdornment,
  InputBase,
  useTheme,
  IconButton,
  FormHelperText,
  Tooltip,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import {
  ArrowUpwardRounded,
  ExpandMoreRounded,
  KeyboardVoice,
  SendRounded,
} from "@mui/icons-material";
import FilesListDialog from "./FilesListDialog";
import { Audio } from "./Audio";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

const useStyles = makeStyles()((theme, _params, classes) => ({
  root: {
    margin: 0,
    borderRadius: 16,
    border: "1px solid rgba(0, 0, 0, 0.23)",
  },
  audioContainer: {
    margin: 0,
    background: theme.palette.paper,
    borderRadius: 16,
    padding: "0px 12px",
  },
  scrollBottomBtn: {
    position: "absolute",
    top: -50,
    right: -40,
    zIndex: 999,
    borderRadius: 8,
    transform: "translateX(-100%)",
    border: `1px solid ${theme.palette.divider}`,
    background: "rgba(255, 255, 255, 0.40)",
    boxShadow:
      "0px 2px 4px -1px rgba(0, 0, 0, 0.20), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
    backdropFilter: "blur(4px)",
    [theme.breakpoints.down("sm")]: {
      top: -40,
      right: -24,
    },
  },
  activeSendBtn: {
    borderRadius: 8,
    cursor: "pointer",
    marginLeft: 12,
    background: theme.palette.light,
    // "&:hover": {
    //   backgroundColor: alpha(theme.palette.secondary.main, 0.9),
    // },
  },
  disabledSendBtn: {
    borderRadius: 8,
    marginLeft: 12,
    cursor: "not-allowed",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  micIcon: {
    color: theme.palette.primary.main, // Change color as needed
    fontSize: "1.5rem", // Adjust size as needed
  },
}));

export const uid = () => {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

const Query = forwardRef(
  (
    {
      size = "medium",
      isNewSession = false,
      handleStreamMessages,
      isStreaming = false,
      isQueryIntentLoading = false,
      queryRef,
      queryInputRef,
    },
    ref
  ) => {
    const location = useLocation();
    const { completedFiles } = useSelector((state) => state.files);
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down("sm"));
    const { classes } = useStyles();
    const [query, setQuery] = useState("");
    const [audioMode, setAudioMode] = useState(false);
    const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(true);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const fileFilter = new URLSearchParams(location.search).get("fileFilter");

    useImperativeHandle(ref, () => ({
      query: query,
      setQuery: handleQueryChange,
    }));

    //TODO:
    useEffect(() => {
      if (fileFilter) {
        try {
          const selectedFiles = JSON.parse(decodeURIComponent(fileFilter));
          if (selectedFiles.length > 0)
            setSelectedFiles(getFilesFromIds(completedFiles, selectedFiles));
        } catch (err) {
          console.error("Failed to parse query param", err);
        }
      } else {
        setSelectedFiles(completedFiles);
      }
    }, [completedFiles]);

    function getFilesFromIds(files, fileIds) {
      let filesArray = fileIds
        .map((id) => files.find((f) => f["id"] === id))
        .filter((f) => f);
      return filesArray;
    }

    async function handleQuerySubmit() {
      const queryValue = query.trim();
      if (queryValue) {
        // handleQueryChange("");
        let selectedFileIds = selectedFiles.map((f) => f["id"]);
        const response = await handleStreamMessages(
          queryValue,
          selectedFileIds
        );
        if (response === "stream_api_error") {
          handleQueryChange(queryValue);
          setIsSendButtonDisabled(false);
        }
      }
    }

    const handleQueryChange = (value) => {
      setQuery(value);
      setTimeout(() => {
        if (queryInputRef.current && value && !query) {
          const inputElement = queryInputRef.current;
          inputElement.focus();
          inputElement.setSelectionRange(value.length, value.length);
        }
      }, 0);
      if (value.trim() === "") {
        if (!isSendButtonDisabled) {
          setIsSendButtonDisabled(true);
        }
      } else {
        if (isSendButtonDisabled) {
          setIsSendButtonDisabled(false);
        }
      }
    };

    function handleOnKeyDown(e) {
      const { key, shiftKey } = e;
      if (key === "Enter" && !shiftKey && query && !isXS && !isStreaming) {
        e.preventDefault();
        handleQuerySubmit();
      }
    }

    return (
      <Box display={"flex"} alignItems={"flex-start"} ref={queryRef}>
        <FilesListDialog
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
        />
        <Box mr={1} />
        <Box width={"100%"}>
          {!audioMode ? (
            <Box display={"flex"} alignItems={"center"}>
              <InputBase
                fullWidth
                className={classes.root}
                sx={{
                  padding: `${size === "medium" ? 16 : 12}px 12px`,
                  "&:hover": {
                    borderColor: theme.palette.text.secondary,
                  },
                  "&.Mui-focused": {
                    borderColor: "transparent",
                    boxShadow: `0 0 0 1px transparent, 0 0 0 2px ${theme.palette.accent}`,
                  },
                  background: `${size === "medium" ? "#f1f1f1" : "#fff"}`,
                }}
                value={query}
                onChange={(e) => handleQueryChange(e.target.value)}
                onKeyDown={handleOnKeyDown}
                endAdornment={
                  <InputAdornment position="end">
                    {isQueryIntentLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <>
                        {isSendButtonDisabled ? (
                          <IconButton onClick={(e) => setAudioMode(true)}>
                            <Tooltip title="Speak your question">
                              <KeyboardVoice />
                            </Tooltip>
                          </IconButton>
                        ) : (
                          <IconButton
                            size="small"
                            onClick={handleQuerySubmit}
                            disabled={!(query && !isStreaming)}
                            className={
                              query && !isStreaming
                                ? classes.activeSendBtn
                                : classes.disabledSendBtn
                            }>
                            <SendRounded
                              sx={{
                                color:
                                  !query || isStreaming ? "inherit" : "#ffffff",
                              }}
                            />
                          </IconButton>
                        )}
                      </>
                    )}
                  </InputAdornment>
                }
                multiline
                maxRows={6}
                inputRef={queryInputRef}
                autoFocus
                placeholder={"Ask anything..."}
              />
            </Box>
          ) : (
            <Box className={classes.audioContainer}>
              <Audio
                recordingError={(e) => {
                  setAudioMode(false);
                  enqueueSnackbar("Permission denied", { variant: "error" });
                }}
                recordingCancelled={(e) => {
                  setAudioMode(false);
                  enqueueSnackbar("Voice query cancelled", {
                    variant: "warning",
                  });
                }}
                recordingText={(text) => {
                  setAudioMode(false);
                  if (text) {
                    // console.log(text);
                    handleQueryChange(text["text"]);
                  } else {
                    enqueueSnackbar("Error processing voice query", {
                      variant: "error",
                    });
                  }
                }}
              />
            </Box>
          )}

          {selectedFiles.length > 0 &&
            selectedFiles.length !== completedFiles.length &&
            !isNewSession && (
              <Box pl={1.5} mt={0.5}>
                <FormHelperText sx={{ color: theme.palette.grey }}>
                  AI will answer within selected files.
                </FormHelperText>
              </Box>
            )}
        </Box>
      </Box>
    );
  }
);
export default Query;
