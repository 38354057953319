/* eslint-disable react/no-array-index-key */
import React, { Fragment, lazy } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import Error404View from "./views/404/Error404View";
import Account from "./views/Account";
import GuestGuard from "./components/GuestGuard";
import AuthGuard from "./components/AuthGuard";
import LoginGuard from "./components/LoginGuard";
import Files from "./views/Files";
import DashboardLayout from "./views/DashboardLayout";
// import Chats from "./views/Chats";
import Login from "./views/Auth/Login";
import Authenticate from "./views/Auth/Authenticate";
import EmailVerification from "./views/Auth/EmailVerification";
import Sessions from "./views/AskAI/Sessions";
import Messages from "./views/AskAI/Messages";
import Followup from "./views/AskAI/Followup";
import NewSession from "./views/AskAI/NewSession";

export const FILES_URL = "/files";
export const CHATS_URL = "/sessions";
export const SESSIONS_URL = "/sessions";
export const AUTHENTICATE_URL = "/authenticate";
export const ACCOUNT_KEY_URL = "/account-key";
export const LOGIN_URL = "/login";
export const ERROR_URL = "/404";
export const EMAIL_VERIFICATION_URL = "/email-verification";
export const NEW_SESSION_URL = "/sessions/new-session";

const routesConfig = [
  {
    exact: true,
    path: "/",
    component: () => <Redirect to={CHATS_URL} />,
  },
  {
    exact: true,
    path: ERROR_URL,
    component: () => <Error404View />,
  },
  ...(process.env.REACT_APP_ENV === "staging"
    ? [
        {
          exact: true,
          path: ACCOUNT_KEY_URL,
          guard: LoginGuard,
          component: () => <Account />,
        },
      ]
    : []),
  {
    exact: true,
    path: LOGIN_URL,
    guard: LoginGuard,
    component: () => <Login />,
  },
  {
    exact: true,
    path: EMAIL_VERIFICATION_URL,
    guard: LoginGuard,
    component: () => <EmailVerification />,
  },
  {
    exact: true,
    path: AUTHENTICATE_URL,
    component: () => <Authenticate />,
  },
  {
    path: "",
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: SESSIONS_URL,
        component: () => <Sessions />,
      },
      {
        exact: true,
        path: NEW_SESSION_URL,
        component: () => <NewSession />,
      },
      {
        exact: true,
        path: "/sessions/:sessionId",
        component: () => <Messages />,
      },
      {
        exact: true,
        path: "/sessions/:sessionId/messages/:messageId",
        component: () => <Followup />,
      },
      {
        exact: true,
        path: FILES_URL,
        component: () => <Files />,
      },
    ],
  },
  {
    component: () => <Redirect to={ERROR_URL} />,
  },
];

const renderRoutes = (routes) => (
  <Switch>
    {routes.map((route, i) => {
      const Guard = route.guard || Fragment;
      const Layout = route.layout || Fragment;
      const Component = route.component;

      return (
        <Route
          key={i}
          path={route.path}
          exact={route.exact}
          render={(props) => (
            <Guard>
              <Layout>
                {route.routes ? (
                  renderRoutes(route.routes)
                ) : (
                  <Component {...props} />
                )}
              </Layout>
            </Guard>
          )}
        />
      );
    })}
  </Switch>
);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
