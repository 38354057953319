import React, { useState, useRef, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import Query from "../Messages/Query";
import SourceDialog from "./SourceDialog";
import EntityDialog from "./EntityDialog";
import ScrollToBottom from "./ScrollToBottom";
import { usePostHog } from "posthog-js/react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import {
  SOURCE_CLICK,
  ENTITY_CLICK,
  ASK_QUESTION_FAILURE,
  ASK_QUESTION_SUCCESS,
  FETCH_METADATA_FAILURE,
  FETCH_METADATA_SUCCESS,
} from "../../../utils/posthogEvents";
import { v4 as uuidv4 } from "uuid";
import {
  getQueryIntent,
  getEntity,
  chatWithAI,
  getChatMetaData,
} from "../../../core/repo/chatRepo";
import { getAIModel } from "../../../core/storage/localStorage";
import {
  addMessageSuccess,
  getSessionMessagesSuccess,
  updateSessionSuccess,
} from "../../../core/events/sessionEvents";
import StreamMessage from "./StreamMessage";
import MessageResults from "../Messages/Results";
import FollowupResults from "../Followup/Results";
import { useLocation, useHistory } from "react-router-dom";
import { updateUsage } from "../../../utils/updateUsage";

function MessagesComponent({ session, messages, message }) {
  const location = useLocation();
  const history = useHistory();
  const posthog = usePostHog();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const queryRef = useRef();
  const queryContentRef = useRef();
  const queryInputRef = useRef();
  const { files } = useSelector((state) => state.files);
  const [source, setSource] = useState(null);
  const [entity, setEntity] = useState(null);
  const [tempQandA, setTempQandA] = useState(null);
  const [model, setModel] = useState(() => getAIModel());
  const [isQueryIntentLoading, setIsQueryIntentLoading] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const [isMetadataLoading, setIsMetadataLoading] = useState(false);
  const { isMessagesFetched } = useSelector((state) => state.session);
  const messagesRef = useRef();
  const messagesEndRef = useRef();
  const messageRefs = useRef({});
  const messageId = new URLSearchParams(location.search).get("messageId");
  const elaborate = new URLSearchParams(location.search).get("elaborate");
  const noScroll = new URLSearchParams(location.search).get("noScroll");

  useEffect(() => {
    if (
      messagesEndRef.current &&
      !(location.state && location.state["scrollPosition"] && !message) &&
      !messageId &&
      !noScroll
    ) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (isMessagesFetched && noScroll && message) {
      history.push({
        pathname: `/sessions/${session["id"]}/messages/${message["query"]["message_id"]}`,
        state: location.state,
      });
    }
  }, [messages.length]);

  useEffect(() => {
    isMessagesFetched && handleQueryParams();
  }, [isMessagesFetched]);

  //whenever user updates the model inside account dialog, model in this page is not updated becasue model is stored inside localstorage.
  //Inorder to trigger storage change, storage event is dispatched inside account dialog.
  //This useeffect listens to the event and updates the model if there is any change.
  useEffect(() => {
    const handleStorage = () => {
      const model = getAIModel();
      setModel(model);
    };

    window.addEventListener("storage", handleStorage);
    return () => window.removeEventListener("storage", handleStorage);
  }, []);

  function handleQueryParams() {
    if (!message) {
      const query = new URLSearchParams(location.search).get("query");
      //This case handles when question is asked on sessions page or new session page. query is sent as query param.
      //If there are any file filters applied before asking question, they will be extracted from the url.
      //Then 'query' query param is removed from the url.
      if (session["title"] === "New Session" && query) {
        const fileFilter = new URLSearchParams(location.search).get(
          "fileFilter"
        );
        const fileFilterList = fileFilter
          ? JSON.parse(decodeURIComponent(fileFilter))
          : [];
        queryContentRef.current?.setQuery(query);
        handleQueryIntent(query, fileFilterList);
        history.push({
          pathname: `/sessions/${session["id"]}`,
          ...(fileFilter
            ? {
                search:
                  "?" +
                  new URLSearchParams({
                    fileFilter,
                  }).toString(),
              }
            : {}),
          state: location.state,
        });
      }
      //This case preserve's the scroll position. setTimeout allows the page to load completely before scrolling.
      else if (
        messagesRef.current &&
        location.state &&
        location.state["scrollPosition"]
      ) {
        setTimeout(() => {
          messagesRef.current.scrollTop = location.state.scrollPosition;
        }, 10);
      }
    } else {
      //handle ask to elaborate. when clicked on ask to elaborate chip in messages page,
      //user is redirected to this page with query param elaborate = true.
      //We make the query intent request and remove elaborate query param from url
      if (messages.length === 0 && elaborate === "true") {
        handleQueryIntent("Elaborate", []);
        history.push({
          pathname: `/sessions/${session["id"]}/messages/${message["query"]["message_id"]}`,
          state: location.state,
        });
      }
    }
    //This case is to scroll to a particular question whose id is messageId query param.
    if (messageId && messageRefs.current[messageId]) {
      messageRefs.current[messageId].scrollIntoView({
        behavior: "smooth",
      });
    }
  }

  const handleSourceChange = (source) => {
    if (source) {
      //check if entity view is open. if so, close it
      if (entity) {
        setEntity(null);
      }

      const { file_id } = source;
      const file = files.find((f) => f["id"] === file_id);
      if (file) {
        setSource(source);
      } else {
        setSource(null);
        enqueueSnackbar("File Not Found", {
          variant: "error",
        });
      }
      posthog.capture(SOURCE_CLICK);
    } else {
      setSource(null);
    }
  };

  const handleEntityChange = (entity) => {
    if (entity) {
      //check if source view is open. if so, close it
      if (source) {
        setSource(null);
      }

      setEntity(entity);
      posthog.capture(ENTITY_CLICK);
    } else {
      setEntity(null);
    }
  };

  async function handleQueryIntent(query, selectedFileIds) {
    setIsQueryIntentLoading(true);
    queryInputRef.current?.blur();
    let queryId = uuidv4();
    let qna = {
      query: query,
      response: null,
      queryId: queryId,
      isEntity: false,
    };
    setTempQandA(qna);
    const threadId = message ? message["query"]["message_id"] : null;
    try {
      const queryIntent = await dispatch(
        getQueryIntent(
          query,
          session["id"],
          queryId,
          threadId,
          true,
          selectedFileIds,
          model
        )
      );
      util();
      if (queryIntent["intent"] === "document_request") {
        setTempQandA({ ...qna, isEntity: true });
        handleEntity(queryId, query, queryIntent, selectedFileIds, threadId);
      } else {
        streamMessages(queryId, query, selectedFileIds, threadId);
      }
    } catch (err) {
      util();
      streamMessages(queryId, query, selectedFileIds, threadId);
      console.log(err);
    }

    function util() {
      queryInputRef.current?.focus();
      setIsQueryIntentLoading(false);
      setTimeout(() => {
        if (messagesEndRef.current) {
          messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }, 10);
      if (queryContentRef.current) {
        queryContentRef.current.setQuery("");
      }
    }
  }

  async function handleEntity(queryId, query, queryIntent, fileIds, threadId) {
    setIsStreaming(true);
    try {
      const entities = await dispatch(
        getEntity(
          queryIntent,
          query,
          session["id"],
          queryId,
          threadId,
          true,
          fileIds,
          model
        )
      );

      let { query_object, answer_object, conversation_title } = entities;
      if (session["title"] === "New Session") {
        dispatch(
          updateSessionSuccess({ ...session, title: conversation_title })
        );
      }
      addMessageToReduxStore(query_object, answer_object);
      updateUsage();
    } catch (err) {
      streamApiError(err, queryId, query);
    }
    setTempQandA(null);
    setIsStreaming(false);
  }

  async function streamMessages(queryId, query, fileIds, threadId) {
    setIsStreaming(true);
    let tempQueryResponse = "";
    try {
      await chatWithAI(
        query,
        session["id"],
        queryId,
        threadId,
        true,
        fileIds,
        model,
        (text) => {
          if (text) {
            setTempQandA({ query, queryId, response: text });
            tempQueryResponse = text;
          }
        }
      );
      posthog.capture(ASK_QUESTION_SUCCESS);
      updateUsage();
    } catch (err) {
      return streamApiError(err, queryId, query);
    }

    let ai_response = {},
      user_query = {},
      title = "New Session";
    setIsMetadataLoading(true);
    try {
      const response = await getChatMetaData(queryId);
      ai_response = response["ai_response"];
      user_query = response["user_query"];
      title = response["title"];
      posthog.capture(FETCH_METADATA_SUCCESS);
    } catch (e) {
      const { userQuery, aiResponse } = getDummyAnswerAndQuery(
        session["id"],
        queryId,
        query,
        tempQueryResponse,
        "metadata_api_fail"
      );
      ai_response = aiResponse;
      user_query = userQuery;
      posthog.capture(FETCH_METADATA_FAILURE, {
        query: query,
        messageId: queryId,
      });
      console.log(e);
    }
    if (session["title"] === "New Session") {
      dispatch(updateSessionSuccess({ ...session, title: title }));
    }
    addMessageToReduxStore(user_query, ai_response);
    setIsMetadataLoading(false);
    setTempQandA(null);
    setIsStreaming(false);
  }

  function streamApiError(err, queryId, query) {
    let errMessage = "Could not connect to server. Please try again.";
    if (err["response"]) {
      const { status } = err["response"];
      switch (status) {
        case 500: {
          errMessage = "Server Error";
          break;
        }
        case 502: {
          errMessage = "Service Unavailable";
          break;
        }
      }
    }
    enqueueSnackbar(errMessage, {
      variant: "error",
    });
    posthog.capture(ASK_QUESTION_FAILURE);
    const { userQuery, aiResponse } = getDummyAnswerAndQuery(
      session["id"],
      queryId,
      query,
      "",
      "stream_api_fail"
    );
    addMessageToReduxStore(userQuery, aiResponse);
    console.log(err);
    setTempQandA(null);
    setIsStreaming(false);
    return "stream_api_error";
  }

  function addMessageToReduxStore(query, answer) {
    dispatch(addMessageSuccess({ query, answer }, message));
  }

  return (
    <Box flex={1} overflow={"auto"}>
      <Grid container height={"100%"}>
        <Grid
          item
          sm={source || entity ? 7 : 12}
          height={"100%"}
          width={"100%"}
          position={"relative"}>
          <Box display={"flex"} flexDirection={"column"} height={"100%"}>
            <Box ref={messagesRef} flex={1} overflow={"auto"}>
              <Box p={{ xs: 2, sm: 3 }} maxWidth={900} margin={"0 auto"}>
                {message ? (
                  <FollowupResults
                    message={message}
                    messages={messages}
                    messageRefs={messageRefs}
                    queryRef={queryRef}
                    handleSourceChange={handleSourceChange}
                    handleEntityChange={handleEntityChange}
                    isStreaming={isStreaming}
                  />
                ) : (
                  <MessageResults
                    messages={messages}
                    handleSourceChange={handleSourceChange}
                    handleEntityChange={handleEntityChange}
                    messagesRef={messagesRef}
                    messageRefs={messageRefs}
                    queryRef={queryRef}
                    isStreaming={isStreaming}
                  />
                )}
                {isStreaming && (
                  <StreamMessage
                    message={message}
                    numOfMessages={messages.length}
                    tempQandA={tempQandA}
                    isMetadataLoading={isMetadataLoading}
                  />
                )}
                <Box ref={messagesEndRef} />
              </Box>
            </Box>
            <ScrollToBottom
              message={message}
              messagesRef={messagesRef}
              messagesEndRef={messagesEndRef}
            />
            <Box
              width={"100%"}
              maxWidth={900}
              margin={"0 auto"}
              px={{ xs: 2, sm: 3 }}
              pt={{ xs: 1, sm: 3 }}
              pb={{ xs: 1, sm: 3 }}>
              <Query
                queryRef={queryRef}
                queryInputRef={queryInputRef}
                ref={queryContentRef}
                handleStreamMessages={handleQueryIntent}
                isStreaming={tempQandA}
                isQueryIntentLoading={isQueryIntentLoading}
              />
            </Box>
          </Box>
        </Grid>
        {source && (
          <SourceDialog
            source={source}
            handleSourceChange={handleSourceChange}
          />
        )}
        {entity && (
          <EntityDialog
            entity={entity}
            handleEntityChange={handleEntityChange}
          />
        )}
      </Grid>
    </Box>
  );
}

function getDummyAnswerAndQuery(
  sessionId,
  queryId,
  query,
  content,
  apiFailType
) {
  const userQuery = {
    content: query,
    content_object: { citations: [], file_ids: [], text: query },
    conversation_id: sessionId,
    thread_id: "",
    threads: [],
    role: "user",
    question_id: "",
    message_id: queryId,
    created_at: new Date().toISOString(),
  };
  const aiResponse = {
    content: content,
    content_object: {
      citations: [],
      file_ids: [],
      text: content,
    },
    conversation_id: sessionId,
    thread_id: "",
    threads: [],
    role: "assistant",
    question_id: queryId,
    message_id: uuidv4(),
    created_at: new Date().toDateString(),
    error: apiFailType,
  };
  return { userQuery, aiResponse };
}

export default MessagesComponent;
