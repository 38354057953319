export const GET_FILES_SUCCESS = "@files/get-files-success";
export const UPLOAD_FILE_SUCCESS = "@files/upload-file-success";
export const UPDATE_FILE_SUCCESS = "@files/update-file-success";
export const DELETE_FILE_SUCCESS = "@files/delete-file-success";
export const LOGOUT_SUCCESS = "@files/logout-success";
export const GET_FILES_INITIATE = "@files/get-files-initiate";
export const GET_FILES_FAILURE = "@files/get-files-failure";
export const CLEAR_FILES = "@files/clear-files";

export function getFilesInitiate() {
  return {
    type: GET_FILES_INITIATE,
  };
}

export function getFilesFailure() {
  return {
    type: GET_FILES_FAILURE,
  };
}

export function getFilesSuccess(files) {
  return {
    type: GET_FILES_SUCCESS,
    payload: {
      files,
    },
  };
}

export function uploadFileSuccess(file) {
  return {
    type: UPLOAD_FILE_SUCCESS,
    payload: {
      file,
    },
  };
}

export function updateFileSuccess(file) {
  return {
    type: UPDATE_FILE_SUCCESS,
    payload: {
      file,
    },
  };
}

export function deleteFileSuccess(fileId) {
  return {
    type: DELETE_FILE_SUCCESS,
    payload: {
      fileId,
    },
  };
}

export function clearFiles() {
  return {
    type: CLEAR_FILES,
  };
}
