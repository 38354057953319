import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Chip,
  Tooltip,
  useTheme,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import {
  CloseRounded,
  ExpandLessRounded,
  ExpandMoreRounded,
} from "@mui/icons-material";
import { getPipelineLogs } from "../../../core/repo/sessionRepo";
import Loader from "../../../components/Loader";
import { getIsDebugModeOn } from "../../../core/storage/localStorage";

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
  },
}));

// const labels = {
//   query_intent: "Query Intent",
//   citation: "Citation",
//   similar_chunks: "Similar Chunks",
//   reranker: "Reranker",
//   llm: "LLM",
// };

function Debug({ query }) {
  const { model } = query;
  const theme = useTheme();
  const [isDebugDialogOpen, setIsDebugDialogOpen] = useState(false);
  const [isDebugMode, setIsDebugMode] = useState(() => getIsDebugModeOn());

  useEffect(() => {
    const handleStorage = () => {
      const isDebugMode = getIsDebugModeOn();
      setIsDebugMode(isDebugMode);
    };

    window.addEventListener("storage", handleStorage);
    return () => window.removeEventListener("storage", handleStorage);
  }, []);

  const handleOpen = () => {
    setIsDebugDialogOpen(true);
  };

  const handleClose = () => {
    setIsDebugDialogOpen(false);
  };

  return (
    <Box>
      {process.env.REACT_APP_ENV === "staging" && isDebugMode && (
        <Tooltip title={"Answered using: " + model}>
          <IconButton onClick={handleOpen} size="small" sx={{ marginLeft: 1 }}>
            <img
              src={
                model === "gpt-4o"
                  ? "/images/chatgpt_logo.png"
                  : "/images/claude_ai_icon.svg"
              }
              alt="model_name"
              height={32}
              width={32}
              style={{ color: theme.palette.text.disabled }}
            />
          </IconButton>
        </Tooltip>
      )}
      {isDebugDialogOpen && (
        <DebugDialog query={query} handleClose={handleClose} />
      )}
    </Box>
  );
}

function DebugDialog({ query, handleClose }) {
  const { model, message_id: questionId } = query;
  const { classes } = useStyles();
  const [steps, setSteps] = useState([]);
  const [selectedStep, setSelectedStep] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  async function fetchPipelineLogs() {
    setIsLoading(true);
    try {
      const response = await getPipelineLogs(questionId);
      setSteps(response || []);
      if (response && response.length > 0) {
        setSelectedStep(response[0]);
      }
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchPipelineLogs();
  }, []);
  return (
    <Dialog
      onClose={handleClose}
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen
      classes={{ paper: classes.root }}>
      <DialogTitle
        sx={{
          background: "#ffffff",
          display: "flex",
          alignItems: "center",
          boxShadow:
            "0 0 1px 0 rgba(0,0,0,0.31), 0 4px 6px -2px rgba(0,0,0,0.25)",
        }}>
        <IconButton edge="start" onClick={handleClose} sx={{ mr: 1 }}>
          <CloseRounded />
        </IconButton>
        <Typography variant="h6">Debug</Typography>
        <Box mr={1.5} />
        <Tooltip title={model}>
          <img
            src={
              model === "gpt-4o"
                ? "/images/chatgpt_logo.png"
                : "/images/claude_ai_icon.svg"
            }
            alt="mode_name"
            height={32}
            width={32}
          />
        </Tooltip>
      </DialogTitle>
      <DialogContent sx={{ overflow: "hidden" }}>
        <Box display={"flex"} flexDirection={"column"} height={"100%"}>
          <Box display="flex" alignItems={"center"} py={3}>
            {steps.map((s) => {
              const { step } = s;
              return (
                <Chip
                  key={step}
                  variant={
                    selectedStep && selectedStep["step"] === step
                      ? "contained"
                      : "outlined"
                  }
                  label={step}
                  sx={{ marginRight: 2, cursor: "pointer" }}
                  onClick={() => setSelectedStep(s)}
                />
              );
            })}
          </Box>
          {selectedStep && (
            <Box flex={1} overflow={"auto"}>
              <Display text={selectedStep["input"]} label={"Input"} />
              <Box mb={3} />
              <Display text={selectedStep["output"]} label={"Output"} />
            </Box>
          )}
        </Box>
        {steps.length === 0 && !isLoading && (
          <Typography>No data to show</Typography>
        )}
        {isLoading && <Loader />}
      </DialogContent>
    </Dialog>
  );
}

function Display({ text, label }) {
  const [open, setOpen] = useState(true);
  return (
    <Box>
      <Box display={"flex"} alignItems={"center"}>
        <Typography variant="subtitle2">{label}</Typography>
        <IconButton sx={{ marginLeft: 1 }} onClick={() => setOpen(!open)}>
          {open ? (
            <ExpandLessRounded sx={{ fontSize: 16 }} />
          ) : (
            <ExpandMoreRounded sx={{ fontSize: 16 }} />
          )}
        </IconButton>
      </Box>
      {open && (
        <Typography>
          <pre style={{ whiteSpace: "pre-wrap" }}>
            {JSON.stringify(text, null, 4)}
          </pre>
        </Typography>
      )}
    </Box>
  );
}

export default Debug;
