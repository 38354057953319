// export default {
//   h1: {
//     fontWeight: 700,
//     fontSize: 36,
//     letterSpacing: "0.25px",
//     lineHeight: "56.02px",
//   },
//   h2: {
//     fontWeight: 500,
//     fontSize: 29,
//     letterSpacing: "-0.24px",
//   },
//   h3: {
//     fontWeight: 600,
//     fontSize: 24,
//     letterSpacing: "-0.06px",
//     lineHeight: "32px",
//   },
//   h4: {
//     fontWeight: 500,
//     fontSize: 20,
//     letterSpacing: "0.15px",
//     lineHeight: "32px",
//   },
//   h5: {
//     fontWeight: 500,
//     fontSize: 16,
//     lineHeight: "24px",
//     letterSpacing: "0.15px",
//   },
//   h6: {
//     fontWeight: 500,
//     fontSize: 14,
//     letterSpacing: "-0.05px",
//   },
//   overline: {
//     fontWeight: 500,
//   },
//   caption2: {
//     fontSize: 10,
//     letterSpacing: "0.4px",
//   },
// };

export default {
  h1: {
    fontWeight: 700,
    fontSize: 88,
    letterSpacing: "1px",
    lineHeight: "88px",
  },
  h2: {
    fontWeight: 700,
    fontSize: 60,
    letterSpacing: "1px",
    lineHeight: "60px",
  },
  h3: {
    fontWeight: 700,
    fontSize: 48,
    letterSpacing: "0px",
    lineHeight: "48px",
  },
  h4: {
    fontWeight: 600,
    fontSize: 34,
    letterSpacing: "0px",
    lineHeight: "51px",
  },
  h5: {
    fontWeight: 500,
    fontSize: 24,
    letterSpacing: "0.15px",
    lineHeight: "32px",
  },
  h6: {
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: "0.15px",
    lineHeight: "32px",
  },
  overline: {
    fontWeight: 500,
  },
  caption2: {
    fontSize: 10,
    letterSpacing: "0.4px",
  },
  label: {
    fontSize: 13,
    fontWeight: 400,
    lineHeight: "18px",
    letterSpacing: "0.16px",
  },
};
