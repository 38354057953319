import { useCallback, useEffect, useRef, useState } from "react";
import { calculateBarData, draw } from "../utils/waveform";
import { Box, useTheme } from "@mui/material";

export const AudioWaveform = ({
  mediaRecorder,
  width = "100%",
  height = "100%",
  barWidth = 2,
  gap = 1,
  backgroundColor = "transparent",
  barColor = "rgb(160, 198, 255)",
  fftSize = 1024,
  maxDecibels = -10,
  minDecibels = -90,
  smoothingTimeConstant = 0.4,
}) => {
  const [context] = useState(() => new AudioContext());
  const [analyser, setAnalyser] = useState(null);
  const canvasRef = useRef(null);
  const theme = useTheme();

  useEffect(() => {
    if (!mediaRecorder.stream) return;

    const analyserNode = context.createAnalyser();
    setAnalyser(analyserNode);
    analyserNode.fftSize = fftSize;
    analyserNode.minDecibels = minDecibels;
    analyserNode.maxDecibels = maxDecibels;
    analyserNode.smoothingTimeConstant = smoothingTimeConstant;
    const source = context.createMediaStreamSource(mediaRecorder.stream);
    source.connect(analyserNode);
  }, [mediaRecorder.stream]);

  useEffect(() => {
    if (analyser && mediaRecorder.state === "recording") {
      report();
    }
  }, [analyser, mediaRecorder.state]);

  const report = useCallback(() => {
    if (!analyser) return;

    const data = new Uint8Array(analyser.frequencyBinCount);

    if (mediaRecorder.state === "recording") {
      analyser.getByteFrequencyData(data);
      processFrequencyData(data);
      requestAnimationFrame(report);
    } else if (mediaRecorder.state === "paused") {
      processFrequencyData(data);
    } else if (mediaRecorder.state === "inactive" && context.state !== "closed") {
      context.close();
    }
  }, [analyser, context.state]);

  const processFrequencyData = (data) => {
    if (!canvasRef.current) return;

    canvasRef.current.style.width = "100%";
    canvasRef.current.style.height = "100%";
    canvasRef.current.width = canvasRef.current.offsetWidth;
    canvasRef.current.height = 36;
    const dataPoints = calculateBarData(data, canvasRef.current.width, barWidth, gap);
    draw(dataPoints, canvasRef.current, barWidth, gap, backgroundColor, barColor);
  };

  return (
    <Box width="100%">
      <canvas
        ref={canvasRef}
        style={{
          aspectRatio: "unset",
          marginTop: 1,
        }}
      />
    </Box>
  );
};
