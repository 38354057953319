import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme, _params, classes) => ({
  gradientText: {
    background: theme.palette.accentGradient,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
}));

export default function ConfirmationDialog({
  onYes,
  onNo,
  title,
  content,
  yesLabel,
  noLabel,
  disableYes = false,
  isDelete,
}) {
  const { classes } = useStyles();
  return (
    <Dialog
      open={true}
      onClose={onNo}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      PaperProps={{
        style: {
          maxWidth: "sm",
        },
      }}>
      <DialogTitle variant="h6" sx={{ display: "flex", alignItems: "center" }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {onNo && (
          <Button onClick={onNo} color="secondary">
            <span className={classes.gradientText}>
              {noLabel ? noLabel : "NO"}
            </span>
          </Button>
        )}
        {onYes && (
          <Button
            onClick={onYes}
            color={isDelete ? "error" : "secondary"}
            disabled={disableYes}>
            {yesLabel ? yesLabel : "YES"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
