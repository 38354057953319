import { combineReducers } from "redux";

import filesReducer from "./filesReducer";
import sessionReducer from "./sessionReducer";

const rootReducer = combineReducers({
  files: filesReducer,
  session: sessionReducer,
});

export default rootReducer;
