import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Snackbar,
  Button,
} from "@mui/material";
import Sidebar from "./Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { getAllFiles } from "../../core/repo/fileRepo";
import { getAllSessions } from "../../core/repo/sessionRepo";
import Loader from "../../components/Loader";
import Navbar from "./Navbar";
import { usePostHog } from "posthog-js/react";
import {
  getAccountDetails,
  getUserDetails,
  setUser,
} from "../../core/storage/localStorage";
import {
  Autorenew,
  CheckCircleOutline,
  CloseRounded,
} from "@mui/icons-material";
import { upgradeRequest, getUserAndAccount } from "../../core/repo/accountRepo";
import useIsMounted from "../../hooks/useIsMounted";

function DashboardLayout({ children }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const posthog = usePostHog();
  const { files } = useSelector((state) => state.files);
  const { sessions } = useSelector((state) => state.session);
  const account = getAccountDetails();
  const user = getUserDetails();
  const [isBannerOpen, setIsBannerOpen] = useState(
    user ? user["isBannerOpen"] : false
  );
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const isMountedRef = useIsMounted();

  async function fetchData() {
    try {
      await dispatch(getUserAndAccount());
      files.length === 0 && dispatch(getAllFiles());
      sessions.length === 0 && dispatch(getAllSessions());
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchData();
    if (account && user)
      posthog.identify(account["name"], { email: user["email"] });
  }, [isMountedRef]);

  useEffect(() => {
    const handleStorage = () => {
      const user = getUserDetails();
      if (user) {
        setIsBannerOpen(user["isBannerOpen"]);
      }
    };

    window.addEventListener("storage", handleStorage);
    return () => window.removeEventListener("storage", handleStorage);
  }, []);

  const handleClose = (upgradeRequest) => {
    setIsBannerOpen(false);
    setUser({
      ...user,
      isBannerOpen: false,
      upgrade_request: upgradeRequest ? true : false,
    });
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      width={"100vw"}
      sx={{ height: "100vh", height: "100svh" }}>
      {/* {isBannerOpen && <Banner onClose={handleClose} />} */}
      <Box
        flex={1}
        overflow={"hidden"}
        display={"flex"}
        flexDirection={{ xs: "column", sm: "row" }}
        bgcolor={"#F4F5FB"}>
        {isMobileView ? <Navbar /> : <Sidebar />}
        <Box
          sx={{ flex: 1, overflow: "auto" }}
          pt={{ xs: 0, sm: 1 }}
          pr={1}
          pb={1}
          pl={{ xs: 1, sm: 0 }}>
          <Box
            bgcolor={theme.palette.background.default}
            border={`1px solid ${theme.palette.divider}`}
            height={"100%"}
            width={"100%"}
            borderRadius={6}>
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function Banner({ onClose }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleUpgradeClick = async () => {
    setIsLoading(true);
    try {
      await dispatch(upgradeRequest());
      setOpen(true);
      setTimeout(() => {
        onClose("upgrade_request");
      }, 5000);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={isLoading ? "center" : "space-between"}
      sx={{
        padding: "6px 16px",
        background: open ? theme.palette.success.main : theme.palette.info.dark,
      }}>
      {open ? (
        <>
          <Box
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            mr={2}>
            <IconButton>
              <CheckCircleOutline fontSize="small" sx={{ color: "white" }} />
            </IconButton>
            <Box mr={1.5} />
            <Typography variant="subtitle2" sx={{ color: "white" }}>
              We'll reach out soon with upgrade steps & payment.
            </Typography>
          </Box>
          <IconButton onClick={() => onClose("upgrade_request")}>
            <CloseRounded fontSize="small" sx={{ color: "white" }} />
          </IconButton>
        </>
      ) : isLoading ? (
        <>
          <IconButton>
            <Autorenew
              fontSize="small"
              sx={{ color: "white" }}
              className="iconLoading"
            />
          </IconButton>
          <Box mr={1.5} />
          <Typography variant="subtitle2" sx={{ color: "white" }}>
            Please wait...
          </Typography>
        </>
      ) : (
        <>
          <Typography
            variant="subtitle2"
            sx={{ width: "100%", color: "white", textAlign: "center" }}>
            Enjoy unlimited queries, image search, API access, and more.{" "}
            <span
              onClick={handleUpgradeClick}
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                fontWeight: 500,
              }}>
              Upgrade to Pro
            </span>
            🚀
          </Typography>
          <Box mr={2} />
          <IconButton onClick={() => onClose()}>
            <CloseRounded fontSize="small" sx={{ color: "white" }} />
          </IconButton>
        </>
      )}
    </Box>
  );
}

export default DashboardLayout;
