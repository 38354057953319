import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormHelperText,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme, _params, classes) => ({
  gradientText: {
    background: theme.palette.accentGradient,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
}));

const validationSchema = yup.object({
  title: yup.string().trim().required("Session name is required."),
});

function EditSessionDialog({ session, handleUpdateSession, onClose }) {
  const { classes } = useStyles();
  const initialValues = {
    title: session["title"],
  };

  function onSubmit(values, { resetForm }) {
    handleUpdateSession({
      id: session["id"],
      title: values["title"],
    });
    onClose();
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      PaperProps={{
        style: {
          maxWidth: "sm",
        },
      }}>
      <DialogTitle variant="h6" sx={{ display: "flex", alignItems: "center" }}>
        Rename Session
      </DialogTitle>
      <DialogContent>
        <form>
          <TextField
            fullWidth
            multiline
            maxRows={4}
            sx={{ marginTop: 1 }}
            variant="outlined"
            label="Name"
            name="title"
            error={Boolean(formik.errors.title)}
            value={formik.values.title}
            onChange={formik.handleChange}
          />
          {formik.errors.title && (
            <FormHelperText error sx={{ ml: 1 }}>
              {formik.errors.title}
            </FormHelperText>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          <span className={classes.gradientText}>Cancel</span>
        </Button>
        <Button
          onClick={formik.handleSubmit}
          variant="contained"
          color="secondary"
          disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditSessionDialog;
