import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {
  CloseRounded,
  FullscreenRounded,
  ZoomInRounded,
  ZoomOutRounded,
} from "@mui/icons-material";

function RenderMarkdown({ message }) {
  const transformLinkUri = (uri) => {
    return uri;
  };

  const processedMessage = preprocessMarkdown(removeIndentations(message));

  function removeIndentations(markdown) {
    return markdown
      .split("\n")
      .map((line) => line.trimStart())
      .join("\n");
  }

  function preprocessMarkdown(markdown) {
    // Remove links starting with "loc://" or "c_id" and clean up commas and new lines
    return markdown
      .replace(/\[(.*?)\]\((loc:\/\/|c_id)[^\)]*\)/g, "") // Remove links
      .replace(/\s*,\s*,/g, ",") // Remove consecutive commas
      .replace(/\s*,\s*\n/g, "\n") // Remove comma before a new line
      .replace(/\n\s*,\s*/g, "\n") // Remove comma after a new line
      .replace(/,\s*$/, "") // Remove trailing commas
      .replace(/\n\s*\n/g, "\n"); // Remove extra new lines
  }

  return (
    <Markdown
      remarkPlugins={[remarkGfm]}
      urlTransform={transformLinkUri}
      components={{
        img: Image,
        a: ({ children, href }) => {
          //   // const url = decodeURI(href);
          //   // Use regular expressions to extract the pg and loc values
          //   // const pgMatch = url.match(/pg=(\d+)/);
          //   // const locMatch = url.match(/loc=\[([0-9,]+)\]/);

          //   // const pg = pgMatch ? pgMatch[1] : null;
          //   // const loc = locMatch ? locMatch[1].split(",").map(Number) : null;
          return href.startsWith("loc://") || href.startsWith("c_id") ? null : (
            <a href={href}>{children}</a>
          );
        },
        p: ({ children, ...rest }) => {
          return (
            <p {...rest} style={{ margin: 0 }}>
              {children}
            </p>
          );
        },
        h6: ({ children, ...rest }) => {
          return (
            <h6 {...rest} style={{ margin: 0 }}>
              {children}
            </h6>
          );
        },
        h5: ({ children, ...rest }) => {
          return (
            <h5 {...rest} style={{ margin: "4px 0px" }}>
              {children}
            </h5>
          );
        },
        h4: ({ children, ...rest }) => {
          return (
            <h4 {...rest} style={{ margin: "4px 0px" }}>
              {children}
            </h4>
          );
        },
        h3: ({ children, ...rest }) => {
          return (
            <h3 {...rest} style={{ margin: "8px 0px" }}>
              {children}
            </h3>
          );
        },
        h2: ({ children, ...rest }) => {
          return (
            <h2 {...rest} style={{ margin: "8px 0px" }}>
              {children}
            </h2>
          );
        },
        h1: ({ children, ...rest }) => {
          return (
            <h1 {...rest} style={{ margin: "8px 0px" }}>
              {children}
            </h1>
          );
        },
        li: ({ children, ...rest }) => {
          return children ? <li {...rest}>{children}</li> : null;
        },
      }}>
      {processedMessage}
    </Markdown>
  );
}

function Image(props) {
  const { ...rest } = props;
  const [open, setOpen] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [loading, setLoading] = useState(true);
  const imgRef = useRef(null);

  useEffect(() => {
    if (imgRef.current && imgRef.current.complete) {
      setLoading(false);
    }
  }, []);

  const handleImageLoad = () => {
    setLoading(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleZoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 0.1, 3)); // Limit max zoom to 3x
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.1, 0.5)); // Limit min zoom to 0.5x
  };

  return (
    <>
      <Box position={"relative"} width={200} height={200} my={2}>
        {loading && (
          <Skeleton
            variant="rectangular"
            width={200}
            height={200}
            sx={{ borderRadius: 3 }}
          />
        )}
        <img
          {...rest}
          ref={imgRef}
          style={{
            width: 200,
            height: 200,
            objectFit: "cover",
            cursor: "pointer",
            borderRadius: 12,
            border: "1px solid rgba(0, 0, 0, 0.3)", // Darker light black border
            display: loading ? "none" : "block",
          }}
          onClick={handleOpen}
          onLoad={handleImageLoad}
        />
        {!loading && (
          <IconButton
            size="small"
            sx={{
              background: "rgba(38, 50, 56, 0.7)", // Lighter background
              color: "white",
              position: "absolute",
              top: 8,
              right: 8,
              zIndex: 1,
              "&:hover": {
                background: "rgba(38, 50, 56, 0.9)", // Slightly darker on hover, but still lighter than original
                color: "white",
              },
            }}
            onClick={handleOpen}>
            <FullscreenRounded />
          </IconButton>
        )}
      </Box>
      <Dialog
        open={open}
        fullWidth
        maxWidth="lg"
        onClose={handleClose}
        PaperProps={{ style: { minHeight: "90%" } }}>
        <DialogTitle
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}>
          <Typography variant="h6">{props["alt"]}</Typography>
          <Box display={"flex"} alignItems={"center"}>
            <IconButton onClick={handleZoomIn}>
              <ZoomInRounded />
            </IconButton>
            <Box mr={1} />
            <IconButton onClick={handleZoomOut}>
              <ZoomOutRounded />
            </IconButton>
            <Box mr={1} />
            <IconButton onClick={handleClose}>
              <CloseRounded />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <img
            {...rest}
            style={{
              transition: "transform 0.3s ease",
              transformOrigin: "center",
              transform: `scale(${zoom})`,
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
              width: "auto",
              height: "auto",
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default RenderMarkdown;
