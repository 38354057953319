import React, { useState } from "react";
import { usePostHog } from "posthog-js/react";
import { makeStyles } from "tss-react/mui";
import { Box, Chip, Typography, useTheme } from "@mui/material";
import MessageDialog from "./MessageDialog";
import { ExpandMoreRounded } from "@mui/icons-material";
import { EXPAND_MAIN_QUESTION } from "../../../utils/posthogEvents";

const useStyles = makeStyles()((theme) => ({
  chip: {
    background: theme.palette.paper,
  },
  expandMoreIcon: {
    marginLeft: 8,
    marginBottom: 4,
    background: "#E0E0E0",
    borderRadius: "50%",
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
  gradientText: {
    background: theme.palette.accentGradient,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
}));

function MainQuestion({ message }) {
  const posthog = usePostHog();
  const theme = useTheme();
  const { classes } = useStyles();
  const { query } = message;
  const [isMessageDialogOpen, setIsMessageDialogOpen] = useState(false);

  const handleOpen = () => {
    setIsMessageDialogOpen(true);
    posthog.capture(EXPAND_MAIN_QUESTION);
  };

  return (
    <Box p={2} borderRadius={3} bgcolor={theme.palette.background.light} mb={3}>
      <Chip
        size="small"
        label={<span className={classes.gradientText}>Main Question</span>}
        classes={{ root: classes.chip }}
      />
      <Box mt={1} display={"flex"} alignItems={"flex-end"}>
        <Typography
          variant={"h5"}
          fontWeight={500}
          sx={{ whiteSpace: "pre-wrap" }}>
          {query["content_object"]["text"]}
        </Typography>
        <ExpandMoreRounded
          fontSize="small"
          className={classes.expandMoreIcon}
          onClick={handleOpen}
        />
      </Box>
      {isMessageDialogOpen && (
        <MessageDialog
          message={message}
          onClose={() => setIsMessageDialogOpen(false)}
        />
      )}
    </Box>
  );
}

export default MainQuestion;
