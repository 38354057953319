import { models } from "../../utils/utils";

export function getAccountDetails() {
  const str = localStorage.getItem("account");
  let account = "";
  if (str) {
    account = JSON.parse(str);
  }

  return account;
}

export function getUserDetails() {
  const str = localStorage.getItem("user");
  let user = "";
  if (str) {
    user = JSON.parse(str);
  }

  return user;
}

export function setAIModel(model) {
  localStorage.setItem("model", model);
}

export function getAIModel() {
  const model = localStorage.getItem("model");
  return model || models[0];
}

export function setIsDebugModeOn(debugMode) {
  localStorage.setItem("debug_mode", debugMode);
}

export function getIsDebugModeOn() {
  const isDebugModeOn = localStorage.getItem("debug_mode");
  if (isDebugModeOn && isDebugModeOn === "false") {
    return false;
  }
  return true;
}

export function setAccount(account) {
  localStorage.setItem("account", JSON.stringify(account));
}

export function clearAccountDetails() {
  localStorage.setItem("account", null);
}

export function setUser(user) {
  localStorage.setItem("user", JSON.stringify(user));
}

export function clearUserDetails() {
  localStorage.setItem("user", null);
  localStorage.setItem("file_grid_view", null);
  localStorage.setItem("debug_mode", null);
  localStorage.setItem("model", null);
  localStorage.setItem("model", null);
}

export function setFileGridViewLocalStorage(value) {
  localStorage.setItem("file_grid_view", value);
}

export function getFileGridViewLocalStorage() {
  let isGridView = localStorage.getItem("file_grid_view") === "true";
  return isGridView;
}
