import { models } from "../../utils/utils";
import accountService from "../api/accountService";
import { clearFiles } from "../events/filesEvents";
import {
  getSessionMessagesSuccess,
  getSessionsSuccess,
} from "../events/sessionEvents";
import {
  clearAccountDetails,
  clearUserDetails,
  getAccountDetails,
  getUserDetails,
  setAccount,
  setAIModel,
  setIsDebugModeOn,
  setUser,
} from "../storage/localStorage";

export function sendMagicLink(email) {
  return async (dispatch) => {
    try {
      const response = await accountService.sendMagicLink(email);
      return response;
    } catch (error) {
      throw error;
    }
  };
}

export function authenticateMagicLink(token) {
  return async (dispatch) => {
    try {
      const response = await accountService.authenticateMagicLink(token);
      const { user, account } = response;
      setUser({
        ...user,
        isBannerOpen:
          account["plan"] === "free" && user["upgrade_request"] === false,
      });
      setAccount(account);
      if (process.env.REACT_APP_ENV === "staging") {
        setIsDebugModeOn(true);
        setAIModel(models[0]);
      }
      return response;
    } catch (error) {
      throw error;
    }
  };
}

export function upgradeRequest() {
  return async (dispatch) => {
    try {
      const response = await accountService.upgradeRequest();
      return response;
    } catch (error) {
      throw error;
    }
  };
}

export function getAccountDetailsFromApi(key) {
  return async (dispatch) => {
    try {
      const response = await accountService.getAccount(key);
      const { user, account } = response;
      setUser({
        ...user,
        isBannerOpen:
          account["plan"] === "free" && user["upgrade_request"] === false,
      });
      setAccount(account);
      if (process.env.REACT_APP_ENV === "staging") {
        setIsDebugModeOn(true);
        setAIModel(models[0]);
      }
      return response;
    } catch (error) {
      throw error;
    }
  };
}

export function getUserAndAccount() {
  return async (dispatch) => {
    try {
      const response = await accountService.getUserAndAccount();
      const userFromLocalStorage = getUserDetails();
      const { user, account } = response;
      setUser({
        ...user,
        isBannerOpen:
          account["plan"] === "free" && user["upgrade_request"] === false,
        session_jwt: userFromLocalStorage["session_jwt"] || null,
      });
      setAccount(account);
      return response;
    } catch (error) {
      throw error;
    }
  };
}

export function updateUser(user) {
  return async (dispatch) => {
    try {
      const response = await accountService.updateUser(user);
      setUser(user);
      return response;
    } catch (error) {
      throw error;
    }
  };
}

export function getAccountIdFromLocal() {
  try {
    const response = getAccountDetails();
    return response["id"];
  } catch (error) {
    console.log(error);
  }
}

export function getAccountNameFromLocal() {
  try {
    const response = getAccountDetails();
    return response["name"];
  } catch (error) {
    console.log(error);
  }
}

export function getAccountDetailsFromLocal() {
  try {
    const response = getAccountDetails();
    return response;
  } catch (error) {
    console.log(error);
  }
}

export function setAccountDetailsInLocal(response) {
  try {
    setAccount(response);
  } catch (err) {
    throw err;
  }
}

export function clearAccountDetailsFromLocal() {
  try {
    clearAccountDetails();
  } catch (err) {
    throw err;
  }
}

export function logout(callSessionRevokeApi = true) {
  return async (dispatch) => {
    try {
      if (callSessionRevokeApi) {
        await accountService.logout();
      }
      clearUserDetails();
      clearAccountDetails();
      dispatch(clearFiles());
      dispatch(getSessionMessagesSuccess([]));
      dispatch(getSessionsSuccess([]));
    } catch (error) {
      clearUserDetails();
      clearAccountDetails();
      dispatch(clearFiles());
      dispatch(getSessionMessagesSuccess([]));
      dispatch(getSessionsSuccess([]));
      // dispatch(clearConversationAndMessage());
      throw error;
    }
  };
}

export function clearDetails() {
  return async (dispatch) => {
    clearUserDetails();
    clearAccountDetails();
    dispatch(clearFiles());
    dispatch(getSessionMessagesSuccess([]));
    dispatch(getSessionsSuccess([]));
  };
}
