import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FILES_URL, LOGIN_URL, SESSIONS_URL } from "../../Routes";
import { getUserDetails, setUser } from "../../core/storage/localStorage";
import { useDispatch } from "react-redux";
import { authenticateMagicLink } from "../../core/repo/accountRepo";
import SplashScreen from "../../components/SplashScreen";
import { useSnackbar } from "notistack";
import { usePostHog } from "posthog-js/react";
import { LOGIN_SUCCESS, LOGIN_FAILURE } from "../../utils/posthogEvents";
import { getAllFiles } from "../../core/repo/fileRepo";

function Authenticate() {
  const posthog = usePostHog();
  const user = getUserDetails();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  async function authenticate() {
    if (user) {
      history.push({ pathname: FILES_URL });
    } else {
      const queryParams = new URLSearchParams(window.location.search);
      const token = queryParams.get("token");
      const tokenType = queryParams.get("stytch_token_type");
      // If a token is found, authenticate it with the appropriate method
      if (token && tokenType) {
        if (tokenType === "magic_links") {
          try {
            await dispatch(authenticateMagicLink(token));
            const files = await dispatch(getAllFiles());
            history.push({
              pathname: files.length === 0 ? FILES_URL : SESSIONS_URL,
            });
            posthog.capture(LOGIN_SUCCESS);
          } catch (err) {
            posthog.capture(LOGIN_FAILURE);
            history.push({ pathname: LOGIN_URL });
            enqueueSnackbar("Failed to Authenticate.", { variant: "error" });
            console.log(err);
          }
        }
      }
    }
  }

  useEffect(() => {
    authenticate();
  }, [user]);

  return <SplashScreen />;
}

export default Authenticate;
