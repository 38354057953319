// import axios from "axios";
// import { getUserDetails } from "../storage/localStorage";

// let instance = null;
// // let token = null;

// // function getJWTtoken() {
// //   let user = getUserDetails();
// //   let token = user && user["session_jwt"];
// //   return token;
// // }

// // export function getInstance() {
// //   if (instance) {
// //     if (token) {
// //       token = getJWTtoken();
// //       return instance;
// //     } else {
// //       token = getJWTtoken();
// //       instance = axios.create({
// //         headers: { "x-session-jwt": token },
// //       });
// //     }
// //   } else {
// //     token = getJWTtoken();
// //     instance = axios.create({
// //       headers: { "x-session-jwt": token },
// //     });
// //   }

// //   return instance;
// // }

// let user = null;

// export function getInstance() {
//   if (instance) {
//     if (user) {
//       user = getUserDetails();
//       return instance;
//     } else {
//       user = getUserDetails();
//       instance = axios.create({
//         headers: {
//           "x-session-jwt": user ? user["session_jwt"] : null,
//           "x-user-id": user ? user["id"] : null,
//         },
//       });
//     }
//   } else {
//     user = getUserDetails();
//     instance = axios.create({
//       headers: {
//         "x-session-jwt": user ? user["session_jwt"] : null,
//         "x-user-id": user ? user["id"] : null,
//       },
//     });
//   }

//   return instance;
// }

// import axios from "axios";
// import { getUserDetails } from "../storage/localStorage";

// const user = getUserDetails();

// const instance = axios.create({
//   headers: {
//     "x-session-jwt": user ? user["session_jwt"] : null,
//     "x-user-id": user ? user["id"] : null,
//   },
// });

// export default instance;

// export const vanillaInstance = axios.create();

import axios from "axios";
import { getAccountDetails, getUserDetails } from "../storage/localStorage";

const user = getUserDetails();
const account = getAccountDetails();
const instance = axios.create();
instance.defaults.headers.common["x-session-jwt"] = user
  ? user["session_jwt"] || null
  : null;
instance.defaults.headers.common["x-user-id"] = user ? user["id"] : null;
instance.defaults.headers.common["x-account-id"] = account
  ? account["id"]
  : null;
export default instance;

export const vanillaInstance = axios.create();

export function getHeaders() {
  return {
    "x-account-id": account["id"],
    "x-user-id": user["id"],
    ...(user["session_jwt"] && {
      "x-session-jwt": user["session_jwt"],
    }),
  };
}
