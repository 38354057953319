import React from "react";
import { Redirect } from "react-router-dom";
import { LOGIN_URL } from "../Routes";
import { getUserDetails } from "../core/storage/localStorage";

function AuthGuard({ children }) {
  const user = getUserDetails();
  if (!user) {
    return <Redirect to={LOGIN_URL} />;
  }

  return children;
}

export default AuthGuard;
