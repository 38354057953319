import React from "react";
import { Button, Box, useTheme, Tooltip } from "@mui/material";
import { AddRounded } from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { GradientIconAccent } from "../../../components/GradientIcon";
import { getSessionMessagesSuccess } from "../../../core/events/sessionEvents";

const useStyles = makeStyles()((theme) => ({
  startIcon: {
    marginRight: 0,
    marginLeft: 0,
  },
  gradientText: {
    background: theme.palette.accentGradient,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
}));

function NewChatButton({ isExpand, onClose }) {
  const history = useHistory();
  const theme = useTheme();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { files } = useSelector((state) => state.files);

  const handleNewChatClick = () => {
    history.push(`/sessions/new-session`);
    dispatch(getSessionMessagesSuccess([]));
    if (onClose) onClose();
  };

  return (
    <Box px={2} py={1}>
      <Tooltip title="Start Session" disableHoverListener={isExpand}>
        <Button
          onClick={handleNewChatClick}
          classes={{ startIcon: !isExpand ? classes.startIcon : null }}
          fullWidth
          variant="outlined"
          color="secondary"
          disabled={files.length === 0}
          startIcon={
            files.length === 0 ? (
              <AddRounded fontSize="small" />
            ) : (
              <GradientIconAccent Icon={AddRounded} sx={{ fontSize: 20 }} />
            )
          }
          sx={{
            borderRadius: 2,
            minWidth: 30,
            padding: 1.2,
            background: "#fff",
            borderColor: theme.palette.accent,
          }}>
          <span className={classes.gradientText}>
            {isExpand ? "Start Session" : ""}
          </span>
        </Button>
      </Tooltip>
    </Box>
  );
}

export default NewChatButton;
